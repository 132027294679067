import Image from 'next/image';
import { head } from 'ramda';
import { FunctionComponent, useEffect, useRef, useState } from 'react';

import { Avatar } from 'components';

import CompanyUserSelector from 'components/CompanySelector';

import { useAuthentication } from 'hooks';

import { User } from 'types/user';

import ArrowDropDown from 'assets/icons/arrow-dropdown.svg';
import ExitToApp from 'assets/icons/exit-to-app.svg';

interface DropdownUsersProps {
  currentUser: User;
}

export const DropdownUsers: FunctionComponent<DropdownUsersProps> = ({
  currentUser,
}) => {
  const node = useRef(undefined);
  const [openDrawer, setOpenDrawer] = useState(false);
  const { handleLogout } = useAuthentication();

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (e: MouseEvent) => {
    if (node.current.contains(e.target)) return;

    setOpenDrawer(false);
  };

  return (
    <div ref={node} className="relative inline-block">
      <ArrowDropDown
        className={`primary-icon  cursor-pointer ${
          openDrawer ? 'open-menu' : 'close-menu'
        }`}
        onClick={() => setOpenDrawer(!openDrawer)}
      />
      {openDrawer && (
        <div className="origin-top-right absolute right-0 mt-3 w-72 rounded-md shadow-bs-primary bg-white">
          <div className="flex flex-col">
            <div className="flex justify-between items-center border-b p-6 ">
              <div className="flex items-center flex-row">
                <Avatar size="big" className="'bg-white ring-white">
                  {currentUser?.src ? (
                    <Image
                      className={`inline-block rounded-full ring-2 ring-white font-aeonik`}
                      src={currentUser.src}
                      width={50}
                      height={50}
                      alt={`${currentUser.username[0]}`}
                    />
                  ) : (
                    <span className="text-xs">
                      {head(currentUser?.username || '')}
                    </span>
                  )}
                </Avatar>
                <div className="flex flex-col ml-3 leading-4">
                  <span className="font-aeonik-medium">
                    {currentUser?.username}
                  </span>
                  <span className="font-aeonik text-xs text-icons">
                    {currentUser?.email}
                  </span>
                </div>
              </div>
            </div>
            <CompanyUserSelector />
            <div
              className="flex flex-row h-16 items-center justify-end px-6 text-icons"
              onClick={handleLogout}
            >
              <div className="flex cursor-pointer">
                <ExitToApp className="fill-current text-icons" />
                <span className="ml-2 font-aeonik-medium text-m text-icons">
                  SIGN OUT
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
