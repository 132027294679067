import { FunctionComponent, useRef, useState } from 'react';

import { useOnClickOutside } from 'hooks';

import More from 'assets/icons/more-vert.svg';

interface MoreOptionsProps {
  children: JSX.Element;
  onDelete?: () => void;
}

const MoreOptions: FunctionComponent<MoreOptionsProps> = ({
  children,
  onDelete,
}) => {
  const [isMenuHidden, setIsMenuHidden] = useState(true);
  const wrapperRef = useRef(null);
  useOnClickOutside(wrapperRef, () => setIsMenuHidden(true));

  return (
    <>
      <div ref={wrapperRef} className="relative">
        <div
          className="wrapperRef disabled:cursor-auto"
          onClick={() => setIsMenuHidden(!isMenuHidden)}
        >
          <More className="cursor-pointer fill-neutrals-60" />
        </div>
        <div
          className={`absolute bg-white origin-top mt-2 -ml-40 rounded-lg-.5 shadow-bs-tertiary z-50 cursor-pointer ${
            !isMenuHidden ? 'block' : ''
          } ${isMenuHidden ? 'hidden' : ''}`}
          onClick={() => setIsMenuHidden(true)}
        >
          <div
            className="flex items-center h-12 w-45 py-4 hover:bg-gray-50 rounded-lg-.5"
            onClick={onDelete}
          >
            <button className="font-aeonik-medium text-sm text-secondary py-4 px-3 border-gray-inactive focus:outline-none">
              {children}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoreOptions;
