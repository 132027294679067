import { MutableRefObject, useRef } from 'react';

export interface FormikArrayHelpers<T> {
  push: (obj: T) => void;
  remove?: (index: number) => void;
  replace?: (index: number, obj: T) => void;
  insert?: (index: number, obj: T) => void;
}

interface FormikArrayHelpersProps<U> {
  arrayHelpersRef: MutableRefObject<FormikArrayHelpers<U>>;
  addItem: (data: U) => void;
  insertItem: (index: number, data: U) => void;
  removeItem: (index: number) => void;
  updateItem: (index: number, data: U) => void;
  setCurrentHelpers: (arrayHelpers: FormikArrayHelpers<U>) => void;
}

const useFormikArray = <T>(): FormikArrayHelpersProps<T> => {
  const arrayHelpersRef = useRef<FormikArrayHelpers<T>>(null);
  const addItem = (data: T): void => {
    arrayHelpersRef?.current?.push(data);
  };
  const insertItem = (index: number, data: T): void => {
    arrayHelpersRef?.current?.insert(index, data);
  };
  const removeItem = (index: number): void => {
    arrayHelpersRef?.current?.remove(index);
  };
  const updateItem = (index: number, data: T): void => {
    arrayHelpersRef?.current?.replace(index, data);
  };

  const setCurrentHelpers = (arrayHelpers: FormikArrayHelpers<T>): void => {
    arrayHelpersRef.current = arrayHelpers;
  };
  return {
    arrayHelpersRef,
    addItem,
    insertItem,
    removeItem,
    updateItem,
    setCurrentHelpers,
  };
};

export default useFormikArray;
