import { FunctionComponent } from 'react';

import WarningError from 'assets/icons/warning-error.svg';

interface ErrorTextProps {
  text: string;
}

const ErrorText: FunctionComponent<ErrorTextProps> = ({ text }) => {
  return (
    <div className='flex justify-center items-center'>
      <span className="text-yellow-40 hover:underline">{text}</span>
      <WarningError className="ml-2" />
    </div>
  );
};

export default ErrorText;
