import {
  Calendar as DatePicker,
  DayRange,
} from '@hassanmojab/react-modern-calendar-datepicker';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

import { DateRange } from 'types/DateRange';

import ArrowDropdown from 'assets/icons/arrow-dropdown.svg';
import Today from 'assets/icons/today.svg';

interface CalendarProps {
  /**
   * Class name to align the component
   */
  className?: string;
  /**
   * Flag to disable button
   */
  isDisable?: boolean;
  /**
   * List title displayed in the dropdown
   */
  title?: string;
  /**
   * function to setDate from parent
   */
  setDate?(value?: unknown): void;
  /**
   * set intial date range value
   */
  initialDayRangeValue?: DateRange;
  selected?: boolean;
}

const DEFAULT_TITLE = 'Date';
const DAY_RANGE = {
  from: null,
  to: null,
};

export const Calendar: FunctionComponent<CalendarProps> = ({
  className,
  isDisable,
  title = DEFAULT_TITLE,
  setDate = () => undefined,
  selected,
}) => {
  const node = useRef(undefined);
  const [isActive, setActive] = useState(false);
  const [dayRange, setDayRange] = useState<DayRange>(DAY_RANGE);
  const [dayRangeAux, setDayRangeAux] = useState<DayRange>(dayRange);
  const currentDate = useRef(dayRange);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    setDayRangeAux(dayRange);
  }, [dayRange]);

  const handleClick = (e: MouseEvent) => {
    if (node.current.contains(e.target)) return;

    setDayRangeAux(currentDate.current);
    setActive(false);
  };

  const onSave = () => {
    setDayRange(dayRangeAux);
    currentDate.current = dayRangeAux;
    setDate(dayRangeAux);
    setActive(false);
  };

  const onClear = () => {
    setDayRangeAux(DAY_RANGE);
  };

  return (
    <div className={`relative inline-block ${className || ''}`} ref={node}>
      <button
        className={`flex h-9 px-4 py-2 items-center border ${
          isActive && !dayRange?.from && !dayRange?.to
            ? 'border-neutrals-60'
            : 'border-neutrals-40'
        } focus:outline-none rounded ${
          dayRange?.from && dayRange?.to ? 'bg-primary-active' : ''
        } ${isDisable ? 'cursor-not-allowed' : ''}`}
        onClick={() => setActive(!isActive)}
        disabled={isDisable}
      >
        <span
          className={`w-5 h-5 ${isActive ? 'fill-current' : 'fill-inactive'} ${
            dayRange?.from && dayRange?.to || selected ? 'color-primary-active' : ''
          }`}
        >
          <Today />
        </span>
        <span
          className={`mx-2.5 ${
            dayRange?.from && dayRange?.to || selected
              ? 'text-primary'
              : isActive
              ? 'text-secondary'
              : 'text-icons'
          } font-aeonik font-medium text-sm`}
        >
          {title}
        </span>
        <ArrowDropdown
          className={`w-5 h-5 transition duration-300 transform ${
            isActive ? 'rotate-180 fill-current' : '-rotate-80 fill-inactive'
          } ${dayRange?.from && dayRange?.to || selected ? 'color-primary-active' : ''}`}
        />
      </button>
      <div
        className={`absolute bg-white origin-top mt-1.5 w-auto rounded-lg-.5 shadow-bs-tertiary z-30 ${
          isActive ? 'block' : ''
        } ${!isActive ? 'hidden' : ''}`}
      >
        <DatePicker
          value={dayRangeAux}
          onChange={setDayRangeAux}
          colorPrimary="#124DF9"
          colorPrimaryLight="#F2F6FF"
        />
        <div className={`flex justify-end items-center h-16 py-2 px-4 z-30`}>
          <button
            onClick={onClear}
            className="font-aeonik-medium text-subtitle-14 text-neutral-100 py-2 px-5 border border-neutral-40 rounded-.5 mr-1.5 focus:outline-none"
          >
            CLEAR
          </button>
          <button
            onClick={onSave}
            disabled={
              (dayRangeAux.from === null && dayRangeAux.to !== null) ||
              (dayRangeAux.from !== null && dayRangeAux.to === null)
            }
            className={`font-aeonik font-normal text-subtitle-14 py-2 px-5 rounded-.5 focus:outline-none ${
              (dayRangeAux.from && dayRangeAux.to) ||
              (!dayRangeAux.from && !dayRangeAux.to)
                ? 'bg-primary-60 text-white'
                : 'text-neutrals-60 bg-neutrals-40'
            }`}
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
