import { useEffect } from 'react';

export type TargetKey = 'Enter' | 'Escape' | 'S' | 'D' | 'N' | 'I' | 'C' | 'A';

const isEditableElement = (element: EventTarget | null): boolean => {
  if (!element) {
    return false;
  }
  return (
    ['INPUT', 'TEXTAREA', 'SELECT', 'BUTTON'].includes(
      (element as HTMLElement).tagName
    ) || (element as HTMLElement).hasAttribute('contenteditable')
  );
};

const useKeyPress = (
  targetKey: TargetKey,
  callback: () => void,
  disabled = false
) => {
  useEffect(() => {
    const handleKeyPress = event => {
      if (disabled || isEditableElement(event.target)) {
        return;
      }
      const normalizedTargetKey = targetKey.toLowerCase();
      if (event.key.toLowerCase() === normalizedTargetKey) {
        callback();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [targetKey, callback]);
};

export default useKeyPress;
