import { useQuery } from '@apollo/client';
import { FunctionComponent, useState } from 'react';

import ConfirmChangesModal from 'components/Modals/ConfirmChanges';

import COMPANIES_DROPDOWN_QUERY from 'api/company';

import useCompanySelector from 'hooks/useCompanySelector';
import { useTracking } from 'hooks/useTracking';

import { TrackingEventAction } from 'utils/constants/trackingEvents';

import { DropdownItem } from 'types/dropdownItem';

import InfoWarning from 'assets/icons/info-warning.svg';
import StoreFront from 'assets/icons/storefront.svg';

const CompanyUserSelector: FunctionComponent = ({}) => {
  const { data: companies } = useQuery<{ companies: DropdownItem[] }>(
    COMPANIES_DROPDOWN_QUERY
  );

  const { trackEvent } = useTracking();

  const [selectedCompanyValue, setSelectedCompanyValue] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { selectedCompany, setCompanyValue } = useCompanySelector();

  const handleCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    trackEvent(TrackingEventAction.COMPANY_DROPDOWN, {
      context: value,
    });
    setSelectedCompanyValue(value);
    setShowConfirmationModal(true);
  };

  const onCancelModal = () => {
    setShowConfirmationModal(false);
  };

  const onConfirmModal = () => {
    setCompanyValue(selectedCompanyValue);
    window.location.reload();
  };

  return (
    <form>
      {showConfirmationModal && (
        <ConfirmChangesModal
          icon={<InfoWarning />}
          title="Confirm Company Change"
          description={`By selecting company, the application context will be loaded for company. Are you sure you want to continue?`}
          confirmText="ACCEPT"
          clickOutside={false}
          onCancel={onCancelModal}
          onConfirm={onConfirmModal}
          isLoading={false}
        />
      )}

      {companies?.companies?.length === 1 && (
        <span className="font-aeonik text-icons flex justify-start items-center gap-2 px-6 py-2 border-b">
          <div className="fill-inactive">
            <StoreFront />
          </div>
          <div className="text-sm">{companies?.companies?.[0].title}</div>
        </span>
      )}

      {companies?.companies?.length > 1 && (
        <div className="my-3 mx-3">
          <label
            htmlFor="company"
            className="block mb-1 font-aeonik text-sm text-icons"
          >
            Selected Company
          </label>

          <select
            id="company"
            onChange={handleCompanyChange}
            value={selectedCompany}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="" selected>
              All Companies
            </option>
            {companies.companies?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.title}
              </option>
            ))}
          </select>
        </div>
      )}
    </form>
  );
};

export default CompanyUserSelector;
