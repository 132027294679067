export const TrackingEventAction = {
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  NAV: 'Navbar Click',
  ORDERS_QUICKVIEW: 'Orders QuickView Open',
  ORDERS_QUICKVIEW_TAB: 'Orders QuickView Tab',
  DEEPLINK: 'QuickView Deeplink',
  COMPANY_DROPDOWN: 'Company Dropdown',
  REGISTRY: 'Registry'
};

export const TrackingEventContext = {
  CREATION: 'creation',
  VIEW: 'view',
};  
