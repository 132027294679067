import { FunctionComponent } from 'react';

export interface SpinnerProps {
  size?: 'extra-small' | 'small' | 'normal' | 'big';
}

function getSize(size: string): string {
  let spinnerSize = '';
  switch (size) {
    case 'extra-small':
      spinnerSize = 'h-5 w-5';
      break;
    case 'small':
      spinnerSize = 'h-16 w-16';
      break;
    case 'normal':
      spinnerSize = 'h-20 w-20';
      break;
    case 'big':
      spinnerSize = 'h-28 w-28';
      break;
    default:
      spinnerSize = 'h-20 w-20';
      break;
  }
  return spinnerSize;
}

const Spinner: FunctionComponent<SpinnerProps> = ({ size }: SpinnerProps) => {
  const spinnerSize = getSize(size);
  return (
    <div
      className={`animate-spinner loader ease-linear rounded-full ${
        size !== 'extra-small' ? 'border-8 border-t-8' : 'border-4 border-t-4'
      } border-gray-200 ${spinnerSize}`}
    ></div>
  );
};

export default Spinner;
