import { FunctionComponent } from 'react';

import { Spinner } from 'components/Icons/Spinner';

import useKeyPress from 'hooks/useKeyPress';

import CenteredModal from '../CenteredModal';

interface ConfirmChangesProps {
  confirmText: string;
  cancelText?: string;
  description: string;
  title: string;
  onCancel: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
  clickOutside?: boolean;
  icon?: JSX.Element;
}

const ConfirmChangesModal: FunctionComponent<ConfirmChangesProps> = ({
  confirmText,
  description,
  title,
  onCancel,
  onConfirm,
  icon,
  clickOutside = false,
  isLoading = false,
  cancelText = 'GO BACK',
}) => {
  useKeyPress('Escape', onCancel);
  useKeyPress('Enter', onConfirm);

  return (
    <CenteredModal
      customZIndex="z-[40]"
      onCancel={() => {
        if (clickOutside) {
          onCancel();
        }
      }}
      isSmall
      hasVariableHeight
    >
      <div className="h-full flex flex-col justify-between">
        <div className="mb-12">
          <div className="w-full flex items-center mb-3.5">
            {icon}
            <div className="text-neutral-100 pl-3">
              <h2 className="mb-1 text-headline-20 leading-7 font-aeonik-medium">
                {title}
              </h2>
            </div>
          </div>
          <div className="text-neutral-80 text-body-14">{description}</div>
        </div>
        <div className="w-full h-7 flex justify-end">
          <button
            className="border flex text-primary-60 items-center w-28 justify-center h-8.5 px-5 py-1.5 bg-white rounded focus:outline-none border-primary-60"
            onClick={onCancel}
          >
            <span className="text-body-14 font-aeonik-medium">
              {cancelText}
            </span>
          </button>
          <button
            className={`flex items-center justify-center h-9 w-28 rounded focus:outline-none ml-0.5 bg-primary-60 text-white`}
            onClick={onConfirm}
          >
            <span className="text-body-14 font-aeonik-medium">
              {isLoading ? (
                <Spinner fillColor={'#FFFFFF'} />
              ) : (
                <>{confirmText}</>
              )}
            </span>
          </button>
        </div>
      </div>
    </CenteredModal>
  );
};

export default ConfirmChangesModal;
