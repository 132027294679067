import { useState, useEffect } from 'react';

import { useCookies } from 'hooks/useCookies';

import { HTTPMethods } from 'utils/enums/HTTPMethods';

interface UseFetchProps {
  url?: string;
  isCallable: boolean;
  method?: HTTPMethods;
}

export interface FetchReturnValues<T> {
  data: T;
  error: unknown;
  loading: boolean;
  getData: <U = T>(dataUrl?: string) => Promise<U>;
  baseUrl?: string;
}

const useFetch = <U>({
  url,
  isCallable,
  method = HTTPMethods.GET,
}: UseFetchProps): FetchReturnValues<U> => {
  const [data, setData] = useState<U>();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const { getCookie } = useCookies();

  const getData = async <T = U>(dataUrl: string = url): Promise<T> => {
    setLoading(true);
    try {
      const response = await fetch(dataUrl, {
        method,
        headers: {
          sid: getCookie('sid'),
          authorization: getCookie('authorization'),
        },
      });
      const dataJson = await response.json();
      setData(dataJson);
      return dataJson;
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isCallable) {
      getData();
    }
  }, [url]);

  return { data, loading, error, getData };
};

export default useFetch;
