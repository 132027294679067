import React from 'react';

import { IndeterminateCheckbox } from 'components';

const checkColumnPush = hooks =>
  hooks.visibleColumns.push(columns => [
    {
      id: 'selection',
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div>
          <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        </div>
      ),
      Cell: ({ row }) => (
        <div>
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        </div>
      ),
    },
    ...columns,
  ]);

export default checkColumnPush;
