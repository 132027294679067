import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { createConsumer } from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import Cookies from 'js-cookie';

import { COOKIES, useCookies } from 'hooks/useCookies';

const url = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT || '';

const constructHeaders = () => {
  const authorization = Cookies.get(COOKIES.authorization) || '';
  const companyId = Cookies.get(COOKIES.companyId) || '';
  const brandId = Cookies.get(COOKIES.brandId) || '';

  return {
    authorization: authorization ? `Bearer ${authorization}` : '',
    'X-Company-Id': companyId ? companyId : '',
    'X-Brand-Id': brandId ? brandId : '',
  };
};

const cable = createConsumer(process.env.NEXT_PUBLIC_SUBSCRIPTIONS_URI);

const actionCableLink = new ActionCableLink({
  cable,
  connectionParams: constructHeaders(),
});

const httpLink = new HttpLink({
  uri: url,
  headers: constructHeaders(),
});

const authLink: ApolloLink = setContext(async (_, { headers }) => {
  const { getCookie } = useCookies();

  const authorization = getCookie(COOKIES.authorization) || '';
  const companyId = getCookie(COOKIES.companyId) || '';
  const brandId = getCookie(COOKIES.brandId) || '';

  return {
    headers: {
      authorization: authorization ? `Bearer ${authorization}` : '',
      'X-Company-Id': companyId ? companyId : '',
      'X-Brand-Id': brandId ? brandId : '',
      ...headers,
    },
  };
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  actionCableLink,
  from([authLink, httpLink])
);

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;