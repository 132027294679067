import { FunctionComponent, Suspense, useEffect, useState } from 'react';

import { Spinner } from 'components';

import Warning from 'assets/icons/warning.svg';

interface DynamicIconProps {
  iconName: string;
}

const DynamicIcon: FunctionComponent<DynamicIconProps> = ({ iconName }) => {
  const [IconComponent, setIconComponent] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    let isMounted = true;

    import(`assets/icons/${iconName}.svg`)
      .then(module => {
        if (isMounted) {
          setIconComponent(module.default);
        }
      })
      .catch(() => setError(true));

    return () => {
      isMounted = false;
    };
  }, [iconName]);

  if (error) {
    return <Warning />;
  }

  if (!IconComponent) {
    return <></>;
  }

  return (
    <Suspense fallback={<Spinner size="extra-small" />}>
      {IconComponent}
    </Suspense>
  );
};

export default DynamicIcon;
