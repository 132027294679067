import { useState } from 'react';

import { COOKIES, useCookies } from 'hooks/useCookies';

const useCompanySelector = () => {
  const { setCookie, getCookie, removeCookie } = useCookies();

  const [selectedCompany, setSelectedCompany] = useState<string | null>(
    getCookie(COOKIES.companyId) || null
  );

  const setCompanyValue = (value: string) => {
    if (!value) {
      removeCookie(COOKIES.companyId);
    }

    if (value) {
      setSelectedCompany(value);
      setCookie(COOKIES.companyId, value);
    }
  };

  return {
    selectedCompany,
    setCompanyValue,
  };
};

export default useCompanySelector;
