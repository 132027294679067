export const NOT_FOUND_MESSAGES = {
  DEEPLINK_NOT_FOUND: {
    title: 'Oops! It seems like this link is invalid or no longer exists.',
    description:
      'We couldn\u0027t find the page you were looking for. It might have been deleted or just doesn\u0027t exist. You can go back to the homepage, try again later, or enter a different link.',
  },
  RECORDS_NOT_FOUND: {
    title: 'Nothing here',
    description:
      'It looks like you don\u0027t have any record with these filters. Please try with others.',
  },
};
