import { forwardRef, LegacyRef, MutableRefObject, useEffect, useRef } from 'react'

interface IndeterminateCheckboxProps {
  checked: boolean;
  title: string;
  indeterminate: boolean;
}

const IndeterminateCheckbox = forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
  ({indeterminate, ...rest}, ref) => {
    const defaultRef = useRef<IndeterminateCheckboxProps>();
    const resolvedRef = ref as MutableRefObject<IndeterminateCheckboxProps> || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const getCurrentRef = () => {
      return resolvedRef as LegacyRef<HTMLInputElement>;
    }

    return (
      <>
        <input
          type="checkbox"
          ref={getCurrentRef()}
          {...rest}
          className="form-checkbox rounded-sm text-primary border-2 border-secondary-items"
        />
      </>
    )
  },
)

IndeterminateCheckbox.displayName = ''

export default IndeterminateCheckbox;