import { CSSProperties, FunctionComponent } from 'react';
import { ITooltip, Tooltip as ReactTooltip } from 'react-tooltip';

import 'react-tooltip/dist/react-tooltip.css';

type ToolTipPreset = 'light' | 'dark';

type ToolTipProps = ITooltip & {
  preset?: ToolTipPreset;
};

const DEFAULT_STYLES: CSSProperties = {
  padding: 0,
  fontSize: 'unset',
  zIndex: 11,
  opacity: 1,
  border: 'none',
};

const TOOLTIP_PRESETS: Record<ToolTipPreset, CSSProperties> = {
  light: {
    ...DEFAULT_STYLES,
  },
  dark: {
    ...DEFAULT_STYLES,
    backgroundColor: '#212121',
    borderRadius: 8,
    fontSize: 13,
  },
};

const Tooltip: FunctionComponent<ToolTipProps> = (props: ToolTipProps) => {
  const preset = TOOLTIP_PRESETS[props.preset] || DEFAULT_STYLES;
  return (
    <ReactTooltip {...props} style={preset}>
      {props.children}
    </ReactTooltip>
  );
};

export default Tooltip;
