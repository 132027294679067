import { FunctionComponent, useEffect, useState } from 'react';

export interface CheckProps {
  /**
   * Title of label
   */
  title?: string;
  subTitle?: string;
  /**
   * Value of checkbox when is selected. For exampleL: audi (Can be same value like title)
   */
  value: string | number;
  isChecked?: boolean;
  onChange?(value: string, isChecked: boolean, title: string): void;
}

export const Check: FunctionComponent<CheckProps> = ({
  title,
  subTitle,
  onChange,
  value,
  isChecked = false,
}: CheckProps) => {
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleChange = e => {
    onChange(e.target.value, !checked, e.target.title);
    setChecked(!checked);
  };

  return (
    <div className="flex justify-start items-center">
      {title ? (
        <>
          <input
            id={String(value)}
            type="checkbox"
            className="rounded-sm text-primary border-2 border-secondary-items mr-2 cursor-pointer"
            onChange={handleChange}
            title={title}
            checked={checked}
            value={value}
          />
        </>
      ) : null}
      <label
        className="select-none text-icons text-sm font-aeonik cursor-pointer whitespace-nowrap"
        htmlFor={String(value)}
      >
        {title} {subTitle && `- ${subTitle}`}
      </label>
    </div>
  );
};
