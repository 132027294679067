import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks';
import { configActions } from 'store/slices';

import { FeatureFlag } from 'types/featureFlags';

interface useFeatureProps {
  name?: string;
  featureFlags?: FeatureFlag[];
}

const useFeature = ({ name = null, featureFlags = [] }: useFeatureProps) => {
  const dispatch = useAppDispatch();

  const feature = useAppSelector(state =>
    state.config.features.find(feature => feature.name === name)
  );

  useEffect(() => {
    if (featureFlags.length) {
      dispatchFeatureFlags(featureFlags);
    }
  }, [featureFlags]);

  const dispatchFeatureFlags = featureFlags => {
    dispatch(
      configActions.setConfigItem({
        data: featureFlags,
        propertyKey: 'features',
      })
    );
  };

  return { 
    isActive: Boolean(feature?.active),
    dispatchFeatureFlags
  };
};

export default useFeature;
