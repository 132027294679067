import useStringFilters from 'hooks/useStringFilters';

import { LocationsFilters } from 'types/locations';

interface locationsFiltersProps {
  locationsFilters: LocationsFilters;
}

interface LocationsFiltersIds {
  buildingFilter?: string[];
  typeFilter?: string[];
  zoneFilter?: string[];
  mixingFilter?: boolean;
  pickableFilter?: boolean;
}

enum LocationsBooleanFilters {
  mixing = 'mixing',
  pickable = 'pickable',
}

const calculateBooleanFilter = (
  locationsFilters: LocationsFilters,
  key: LocationsBooleanFilters
) => {
  if (locationsFilters?.[key]) {
    const selectedItems = locationsFilters?.[key].filter(
      item => item.isSelected
    );

    if (selectedItems.length === 0) {
      return undefined;
    } else if (selectedItems.length > 1) {
      return null;
    } else {
      return selectedItems[0].value === 'true';
    }
  }
  return true;
};

const useLocationsStringFilters = ({
  locationsFilters,
}: locationsFiltersProps): LocationsFiltersIds => {
  const buildingFilter = useStringFilters({
    arrayOfFilterObjects: locationsFilters?.building,
  });

  const zoneFilter = useStringFilters({
    arrayOfFilterObjects: locationsFilters?.zone,
  });

  const typeFilter = useStringFilters({
    arrayOfFilterObjects: locationsFilters?.locationType,
  });

  const mixingFilter = calculateBooleanFilter(
    locationsFilters,
    LocationsBooleanFilters.mixing
  );

  const pickableFilter = calculateBooleanFilter(
    locationsFilters,
    LocationsBooleanFilters.pickable
  );

  return {
    buildingFilter,
    zoneFilter,
    typeFilter,
    mixingFilter,
    pickableFilter,
  };
};

export default useLocationsStringFilters;
