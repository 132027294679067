import { ErrorMessage, Field, FieldProps } from 'formik';
import { path } from 'ramda';
import { FunctionComponent, FocusEvent, ChangeEvent } from 'react';
import { NumericFormat } from 'react-number-format';

import { ListItems } from 'utils/constants/DimensionsLists';

interface InputDropdownProps {
  name: string;
  disabled?: boolean;
  className?: string;
  label: string;
  list?: Array<ListItems>;
  readonly?: boolean;
  placeholder?: string;
  listFormName?: string;
  onBlur?(event: FocusEvent<HTMLInputElement, Element>): void;
  onChange?(event: ChangeEvent<HTMLSelectElement | HTMLInputElement>): void;
}

const InputDropdown: FunctionComponent<InputDropdownProps> = ({
  name,
  disabled,
  className = '',
  label,
  list,
  listFormName = '',
  readonly = false,
  placeholder = '',
  onBlur,
  onChange,
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        const labelFieldValue =
          path<string>(listFormName.split('.'), form.values) || '';
        return (
          <div className={`relative ${disabled ? 'opacity-60' : ''} `}>
            <label
              className={`text-sm ${
                meta.error && meta.touched
                  ? 'text-mvs-text-negative'
                  : 'text-mvs-text-secondary'
              }`}
            >
              {label}
            </label>
            <div
              className={`flex h-14 shadow-sm rounded-l-md ${
                meta.error && meta.touched
                  ? 'rounded-md border border-mvs-text-negative'
                  : 'border-none'
              }`}
            >
              <select
                name={listFormName}
                value={labelFieldValue}
                className="bg-neutrals-10 border border-neutral-120 rounded-l-md font-aeonik focus:outline-none"
                onChange={e => {
                  if (listFormName && listFormName !== '') {
                    form.setFieldValue(listFormName, e.target.value);
                  }
                  form.handleChange(e);
                  onChange && onChange(e);
                }}
              >
                {list.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                    className="border-none bg-white outline-none focus:bg-transparent hover:bg-transparent"
                  >
                    {option.title}
                  </option>
                ))}
              </select>
              <NumericFormat
                {...field}
                className={`${className} w-full border border-neutral-120 rounded-r-md border-l-0 p-4 font-aeonik focus:border-none focus:ring-mvs-border-main focus:ring-inset`}
                disabled={disabled}
                onBlur={e => {
                  form.handleBlur(e);
                  onBlur && onBlur(e);
                }}
                onChange={e => {
                  const value = e.target.value ? Number(e.target.value) : null;
                  form.setFieldValue(name, value);
                  onChange && onChange(e);
                }}
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                autoComplete="off"
                valueIsNumericString={true}
                placeholder={placeholder}
                required
                readOnly={readonly}
                type="text"
              />
            </div>
            <ErrorMessage
              name={name}
              component="span"
              className="text-xs text-mvs-text-negative"
            />
          </div>
        );
      }}
    </Field>
  );
};

export default InputDropdown;
