import { FilterItem } from 'types/apiVariables';

type StringFilterHookProps = {
  arrayOfFilterObjects: Array<FilterItem>;
};

/**
 * This hook is use to create an array of strings to filter information.
 * The values in the array are the value in the porperties of the filter.
 *
 * @params { array: filters[] }
 * @return ['selectedFilter1.value','selectedFilter2.value',...]'}
 */
const useStringFilters = ({
  arrayOfFilterObjects,
}: StringFilterHookProps): Array<string> =>
  arrayOfFilterObjects
    ?.filter(filterItem => filterItem.isSelected)
    .map(filterItem => filterItem.value);

export default useStringFilters;
