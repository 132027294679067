import { FunctionComponent, InputHTMLAttributes } from 'react';

import { Check } from 'components/Icons/Check';

import styles from './styles.module.css';

interface InputCheckProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
}

const InputCheckbox: FunctionComponent<InputCheckProps> = fields => {
  const { id, disabled } = fields;
  return (
    <label
      htmlFor={id}
      className={`relative cursor-${disabled ? 'default' : 'pointer'}`}
    >
      <div className="w-4.75 h-4.75 relative">
        <input
          {...fields}
          type="checkbox"
          className={`${styles.inputCheckbox} absolute checked:focus:bg-transparent checked:focus:border-primary-60 checked:hover:bg-transparent checked:hover:border-primary-60 focus:bg-transparent focus:border-primary-60 checked:bg-transparent disabled:focus:border-transparent disabled:focus:outline-transparent checked:border-primary-60 disabled:border-neutrals-40 rounded-sm bg-none appearance-none border-checkbox border-primary w-full h-full`}
        />
        <span
          className={`${styles.inputCheckboxIcon} absolute left-0 top-0 icon opacity-0 transition`}
        >
          <Check />
        </span>
      </div>
    </label>
  );
};

export default InputCheckbox;
