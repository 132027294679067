import { useState, useEffect } from 'react';

const useDebouncedValue = (value: string, wait: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const id = setTimeout(
      () => setDebouncedValue(value.toLowerCase().trim()),
      wait
    );

    return () => clearTimeout(id);
  }, [value]);

  return debouncedValue;
};

export default useDebouncedValue;
