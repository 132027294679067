import { configureStore } from '@reduxjs/toolkit';

// Slices
import { configSlice, sessionSlice } from './slices';

export const store = configureStore({
  reducer: {
    config: configSlice.reducer,
    session: sessionSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
