import { FunctionComponent } from 'react';

export const NoResultsIcon: FunctionComponent = () => {
  return (
    <svg
      width="200"
      height="151"
      viewBox="0 0 144 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M143.603 4.74732V93.0043C143.603 95.5937 141.446 97.7516 138.856 97.7516H4.74722C2.15783 97.8595 0 95.7016 0 93.1122V4.74732C0 2.04998 2.15783 0 4.74722 0H138.856C141.554 0 143.603 2.15787 143.603 4.74732Z"
        fill="url(#paint0_linear_5755_49639)"
      />
      <path
        d="M143.916 4.7522V11.5495H0.3125V4.7522C0.3125 2.05486 2.47033 0.00488281 5.05972 0.00488281H139.169C141.866 0.00488281 143.916 2.16276 143.916 4.7522Z"
        fill="url(#paint1_linear_5755_49639)"
      />
      <path
        d="M6.67696 7.77197C7.57077 7.77197 8.29534 7.04738 8.29534 6.15356C8.29534 5.25974 7.57077 4.53516 6.67696 4.53516C5.78316 4.53516 5.05859 5.25974 5.05859 6.15356C5.05859 7.04738 5.78316 7.77197 6.67696 7.77197Z"
        fill="#F9FAFB"
      />
      <path
        d="M11.7473 7.77197C12.6411 7.77197 13.3656 7.04738 13.3656 6.15356C13.3656 5.25974 12.6411 4.53516 11.7473 4.53516C10.8535 4.53516 10.1289 5.25974 10.1289 6.15356C10.1289 7.04738 10.8535 7.77197 11.7473 7.77197Z"
        fill="#F9FAFB"
      />
      <path
        d="M16.9289 7.77197C17.8227 7.77197 18.5473 7.04738 18.5473 6.15356C18.5473 5.25974 17.8227 4.53516 16.9289 4.53516C16.0351 4.53516 15.3105 5.25974 15.3105 6.15356C15.3105 7.04738 16.0351 7.77197 16.9289 7.77197Z"
        fill="#F9FAFB"
      />
      <path
        d="M111.88 98.0224L93.2852 79.4425L97.548 75.1797L116.128 93.7743L111.88 98.0224Z"
        fill="url(#paint2_linear_5755_49639)"
      />
      <path
        d="M120.127 108.269L101.783 89.9243C101.356 89.4981 101.356 88.8072 101.783 88.3662L106.472 83.6771C106.898 83.2509 107.589 83.2509 108.03 83.6771L126.375 102.022C126.801 102.448 126.801 103.139 126.375 103.58L121.685 108.269C121.244 108.695 120.554 108.695 120.127 108.269Z"
        fill="url(#paint3_linear_5755_49639)"
      />
      <path
        d="M72.2342 20C52.7724 20 37 35.7724 37 55.2342C37 74.6814 52.7724 90.4685 72.2195 90.4685C91.6814 90.4685 107.454 74.6961 107.454 55.2342C107.454 35.7724 91.6814 20 72.2342 20ZM72.2342 83.942C56.6089 83.942 43.9528 71.0801 43.9528 55.2195C43.9528 39.359 56.6089 26.5118 72.2342 26.5118C87.8596 26.5118 100.516 39.3737 100.516 55.2342C100.516 71.0948 87.8449 83.942 72.2342 83.942Z"
        fill="url(#paint4_linear_5755_49639)"
      />
      <path
        d="M83.4017 61.6105L77.0595 55.2683L83.4017 48.9261C84.6702 47.6576 84.8111 45.5435 83.4017 44.1341C82.1333 42.8657 80.0192 42.7248 78.6098 44.1342L72.2676 50.4764L65.9253 44.1342C64.6569 42.8657 62.5428 42.7248 61.1334 44.1341C59.724 45.5435 59.7241 47.5167 61.1334 48.9261L67.4757 55.2683L61.1334 61.6105C59.865 62.879 59.724 64.9931 61.1334 66.4024C62.4019 67.6709 64.516 67.8118 65.9253 66.4024L72.2676 60.0602L78.6098 66.4024C79.8783 67.6709 81.9923 67.8118 83.4017 66.4024C84.8111 64.9931 84.6702 62.879 83.4017 61.6105Z"
        fill="url(#paint5_linear_5755_49639)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5755_49639"
          x1="75.5"
          y1="60"
          x2="16.9741"
          y2="253.946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0048FF" stopOpacity="0" />
          <stop offset="1" stopColor="#0048FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5755_49639"
          x1="163"
          y1="12"
          x2="162.317"
          y2="-1.72806"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0000FF" stopOpacity="0" />
          <stop offset="1" stopColor="#0000FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5755_49639"
          x1="112.924"
          y1="86.9416"
          x2="102.924"
          y2="72.1016"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0000FF" stopOpacity="0" />
          <stop offset="1" stopColor="#0000FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5755_49639"
          x1="102.404"
          y1="98.0666"
          x2="114.799"
          y2="86.3834"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0000FF" stopOpacity="0" />
          <stop offset="1" stopColor="#0000FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5755_49639"
          x1="133"
          y1="13"
          x2="49.7383"
          y2="6.95851"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0000FF" stopOpacity="0" />
          <stop offset="1" stopColor="#0000FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5755_49639"
          x1="105"
          y1="63"
          x2="76.4197"
          y2="25.6227"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0000FF" stopOpacity="0" />
          <stop offset="1" stopColor="#0000FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
