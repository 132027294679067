import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menu: [],
  features: [],
  weight_formats: {},
  currency_formats: {},
  length_formats: {},
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfigItem(state, action) {
      const { propertyKey, data } = action.payload;
      state[propertyKey] = data;
    },
  },
});

export const configActions = configSlice.actions;
