import Image from 'next/image';
import Link from 'next/link';

import { AvatarGroup } from 'components/AvatarGroup';
import { DropdownUsers } from 'components/DropdownUsers';

import useUserData from 'hooks/useUserData';

const Header = () => {
  const { currentUser } = useUserData();

  return (
    <header className="flex justify-between items-center w-full px-7 h-70 relative shadow bg-white z-20">
      <div className="flex items-center cursor-pointer">
        <Link href="/orders/overview" className="flex items-center">
          <Image
            src="/img/endless-logo.svg"
            width={170}
            height={44}
            alt="Manifest logo"
          />
        </Link>
      </div>
      <div className="flex items-center">
        <div className="mr-1.5">
          <AvatarGroup users={[currentUser]} max={3} />
        </div>
        <DropdownUsers currentUser={currentUser} />
      </div>
    </header>
  );
};

export default Header;
