import { useState } from 'react';

import { configActions } from 'store/slices/configSlice';

import { APPLICATION_DATA } from 'api/mocks';

import { Metadata } from 'types/configTypes';

import { useAppDispatch } from './redux';

const useSessionData = () => {
  const dispatch = useAppDispatch();
  const [metadata, setMetadata] = useState<Metadata>();

  const fetchAppData = () => {
    try {
      setMetadata(APPLICATION_DATA);
      dispatchMetadataItems(APPLICATION_DATA);
      return metadata;
    } catch (e) {
      throw e;
    }
  };

  const dispatchMetadataItems = (medatata: Metadata) => {
    for (const propertyKey in medatata) {
      dispatch(
        configActions.setConfigItem({
          data: medatata[propertyKey],
          propertyKey,
        })
      );
    }
  };

  return {
    metadata,
    fetchAppData,
  };
};

export default useSessionData;
