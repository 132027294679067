import { clone, equals } from 'ramda';
import {
  ReactElement,
  FunctionComponent,
  useRef,
  useState,
  useEffect,
} from 'react';

import { Search, Spinner } from 'components';

import { Check } from 'components/Check';
import { Spinner as SpinnerLoader } from 'components/Icons/Spinner';

import { useDebouncedValue } from 'hooks';

import useOnScreen from 'hooks/useOnScreen';

import { MINIMUM_WAIT_TIME } from 'utils/constants/table';

import { DropdownItem } from 'types/dropdownItem';

import ArrowDropdown from 'assets/icons/arrow-dropdown.svg';
import Clear from 'assets/icons/clear.svg';

const DEFAULT_TITLE = '';

export interface DropdownCheckProps {
  /**
   * Flag to disable button
   */
  isDisabled?: boolean;
  /**
   * List of Brand items to be displayed
   */
  listItems?: DropdownItem[];
  /**
   * Icon to display in the button text
   */
  icon?: ReactElement;
  /**
   * Allow single element
   */
  allowSingleValue?: boolean;
  /**
   * Selected item from the list
   */
  selected?: string;
  /**
   * List title displayed in the dropdown
   */
  title?: string;
  /**
   * Class name to align the component
   */
  className?: string;
  /**
   * onChange event for radio button list
   */
  onChange?(items: Array<DropdownItem>): void;
  /**
   * onChangeSearch event for search input
   */
  onChangeSearch?(inputValue: string): void;
  /**
   * onCheck event for check input
   */
  onCheck?(value: string | number, isCheked: boolean, title: string): void;
  /**
   * Flag to indicate initial data loading
   */
  loading?: boolean;
  /**
   * Flag to indicate a new selection has been made
   */
  loadingNewSelection?: boolean;
  /**
   * apply right-0 to avoid unnecessary events on dropdowns
   */
  shouldBreakOnViewport?: boolean;
  /**
   * Flag to conditionally show or hide the search input
   */
  showSearch?: boolean;
  /**
   * Custom styles for dropdown container
   */
  customDropdownClasses?: string;
}

const DropdownCheck: FunctionComponent<DropdownCheckProps> = ({
  allowSingleValue = false,
  className,
  customDropdownClasses,
  icon,
  isDisabled = false,
  listItems,
  loading = false,
  loadingNewSelection = false,
  selected,
  shouldBreakOnViewport = false,
  showSearch = false,
  title = DEFAULT_TITLE,
  onChange = () => undefined,
  onChangeSearch,
  onCheck,
}: DropdownCheckProps) => {
  const node = useRef(undefined);
  const wrapperRef = useRef(undefined);
  const { isIntersecting, checkNodeInViewport } = useOnScreen(wrapperRef, node);
  const [isActive, setActive] = useState(() => false);
  const [listSelectedItems, setListSelectedItems] = useState<DropdownItem[]>(
    []
  );
  const [listSelectedItemsAux, setListSelectedItemsAux] = useState<
    DropdownItem[]
  >([]);
  const [disableButton, setDisable] = useState(
    equals(listSelectedItems, listSelectedItemsAux)
  );
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebouncedValue(search, MINIMUM_WAIT_TIME);

  useEffect(() => {
    setDisable(equals(listSelectedItems, listSelectedItemsAux));
  }, [listSelectedItems, listSelectedItemsAux]);

  useEffect(() => {
    if (onChangeSearch) {
      onChangeSearch(debouncedSearch);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (isActive && shouldBreakOnViewport) {
      checkNodeInViewport();
    }
  }, [isActive]);

  useEffect(() => {
    const pageClickEvent = e => {
      if (node.current !== null && !node.current.contains(e.target)) {
        setActive(!isActive);
        setListSelectedItemsAux(clone(listSelectedItems));
      }
    };

    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }
    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isActive]);

  useEffect(() => {
    setListSelectedItemsAux(clone(listItems));
    setListSelectedItems(clone(listItems));
  }, [listItems]);

  useEffect(() => {
    if (
      !isActive &&
      search !== '' &&
      listSelectedItemsAux.every(item => !item.isSelected)
    ) {
      setSearch('');
    }
  }, [isActive, search, listSelectedItemsAux]);

  const handleCheck = (
    value: string | number,
    isCheked: boolean,
    title: string
  ) => {
    onCheck && onCheck(value, isCheked, title);
    const items = listSelectedItemsAux.map((item: DropdownItem) => {
      if (item.value === value) {
        item.isSelected = isCheked;
        return item;
      }

      if (allowSingleValue) {
        item.isSelected = false;
      }
      return item;
    });
    setListSelectedItemsAux(items);
  };

  const optionList = () => {
    return (
      <>
        {listSelectedItemsAux.map((option: DropdownItem, index) => (
          <div
            key={`${option.value}-${option.title}-${index}`}
            className="flex items-center cursor-pointer mb-6 select-none"
          >
            <Check
              value={option.value}
              title={option.title}
              subTitle={option.extra || null}
              onChange={handleCheck}
              isChecked={option.isSelected}
            />
          </div>
        ))}
        {showSearch && selectedElementsList.length > 0 && (
          <div className="flex flex-wrap mb-2">{selectedElementsList}</div>
        )}
      </>
    );
  };

  const selectedElementsList = listSelectedItemsAux
    .filter((option: DropdownItem) => option.isSelected)
    .map((option: DropdownItem, index) => (
      <div
        key={`${option.value}-${option.title}-${index}`}
        className="flex items-center mb-2 select-none"
      >
        <div className="bg-primary-10 py-1 px-3 mr-2">
          <span className="text-primary-60 text-sm">{option.title}</span>
        </div>
      </div>
    ));

  const onClear = () => {
    const items = listSelectedItemsAux.map(item => {
      item.isSelected = false;
      return item;
    });
    setListSelectedItemsAux(items);
  };

  const onSave = () => {
    const cloneArray = clone(listSelectedItemsAux);
    setListSelectedItems(cloneArray);
    setActive(false);
    onChange(cloneArray);
  };

  return (
    <div className={`relative inline-block ${className || ''}`} ref={node}>
      <button
        className={`flex h-9 px-4 py-2 items-center border ${
          isActive && !selected ? 'border-neutrals-60' : 'border-neutrals-40'
        } focus:outline-none rounded ${selected ? 'bg-primary-active' : ''} ${
          isDisabled ? 'cursor-not-allowed opacity-60' : ''
        }`}
        onClick={() => setActive(!isActive)}
        disabled={isDisabled}
      >
        {icon && (
          <span
            className={`w-5 h-5 ${
              isActive ? 'fill-current' : 'fill-inactive'
            } ${selected ? 'color-primary-active' : ''}`}
          >
            {icon}
          </span>
        )}

        <span
          className={`mx-2.5 ${
            selected
              ? 'text-primary-60'
              : isActive
              ? 'text-neutral-100'
              : 'text-neutral-80'
          } font-aeonik font-medium text-sm`}
        >
          {selected || title}
        </span>
        <ArrowDropdown
          className={`w-5 h-5 transition duration-300 transform ${
            isActive ? 'rotate-180 fill-current' : '-rotate-80 fill-inactive'
          } ${selected ? 'color-primary-active' : ''}`}
        />
        {selected && loadingNewSelection && (
          <SpinnerLoader fillColor="#0000FF" />
        )}
      </button>
      <div
        ref={wrapperRef}
        className={`absolute bg-white origin-top mt-1.5 ${customDropdownClasses} pt-4 rounded-lg-.5 shadow-bs-tertiary z-40
        ${isActive ? 'block' : 'hidden'} ${
          isIntersecting ? 'right-0' : 'left-0'
        }`}
      >
        <div className="flex justify-between mb-5 px-5">
          <span className="font-aeonik-medium">{title}</span>
          <Clear
            className="w-5 h-5 fill-inactive"
            onClick={() => setActive(false)}
          />
        </div>
        <div className="m-2">
          {showSearch && (
            <Search
              customWidth="w-full"
              onChange={inputValue => setSearch(inputValue)}
              initialSearchValue={search}
            />
          )}
        </div>
        <div className="flex flex-col max-h-60 overflow-auto px-5 pt-2">
          {loading ? (
            <div className="flex h-60 w-full items-center justify-center">
              <Spinner size="normal" />
            </div>
          ) : listSelectedItemsAux?.length ? (
            optionList()
          ) : null}
        </div>
        <div className={'flex justify-end items-center h-16 py-2 px-4'}>
          <button
            onClick={onClear}
            disabled={disableButton && loading}
            className="font-aeonik-medium text-body-14 text-neutral-100 py-2 px-5 border border-neutral-40 rounded-.5 mr-1.5 focus:outline-none"
          >
            CLEAR
          </button>
          <button
            disabled={disableButton || loading}
            onClick={onSave}
            className={`font-aeonik font-normal text-body-14 py-2 px-5 rounded-.5 focus:outline-none ${
              disableButton || loading
                ? 'text-neutrals-60 bg-neutrals-40'
                : 'text-white bg-primary-60'
            }`}
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  );
};

export default DropdownCheck;
