import NProgress from 'nprogress';

const useProgressBar = () => {
  const startProgressBar = () => {
    NProgress?.start();
  };
  const stopProgressBar = () => {
    NProgress?.done();
  };

  return {
    startProgressBar,
    stopProgressBar,
  };
};

export default useProgressBar;
