export const generateSid = (charsNumber: number): string => {
  let result = '';
  const CHARS =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charsLength = CHARS.length;

  for (let i = 0; i < charsNumber; i++) {
    result += CHARS.charAt(Math.floor(Math.random() * charsLength));
  }

  return result;
};
