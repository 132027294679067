import { clone, equals } from 'ramda';
import {
  ReactElement,
  FunctionComponent,
  useRef,
  useState,
  useEffect,
} from 'react';

import { Search, Spinner } from 'components';

import { CheckInput } from 'components/CheckInput';
import { Spinner as SpinnerLoader } from 'components/Icons/Spinner';

import useOnScreen from 'hooks/useOnScreen';

import { DropdownItem } from 'types/dropdownItem';

import ArrowDropdown from 'assets/icons/arrow-dropdown.svg';
import Clear from 'assets/icons/clear.svg';

const DEFAULT_TITLE = '';

export interface DropdownProps {
  /**
   * Flag to disable button
   */
  isDisabled?: boolean;
  /**
   * List of Brand items to be displayed
   */
  listItems?: DropdownItem[];
  /**
   * Icon to display in the button text
   */
  icon?: ReactElement;
  /**
   * Selected item from the list
   */
  selected?: string;
  /**
   * List title displayed in the dropdown
   */
  title?: string;
  /**
   * Class name to align the component
   */
  className?: string;
  /**
   * onChange event for radio button list
   */
  onChange?(items: Array<DropdownItem>): void;
  /**
   * Flag to indicate initial data loading
   */
  loadingInitialData?: boolean;
  /**
   * Flag to indicate a new selection has been made
   */
  loadingNewSelection?: boolean;
  /**
   * apply right-0 to avoid unnecessary events on dropdowns
   */
  shouldBreakOnViewport?: boolean;
  renderVariant?: boolean;
  isSearchEnabled?: boolean;
  isOneSelectedOnly?: boolean;
}

const Dropdown: FunctionComponent<DropdownProps> = ({
  isDisabled = false,
  title = DEFAULT_TITLE,
  listItems,
  selected,
  className,
  icon,
  isSearchEnabled = true,
  isOneSelectedOnly = false,
  loadingInitialData = false,
  loadingNewSelection = false,
  shouldBreakOnViewport = false,
  renderVariant = false,
  onChange = () => undefined,
}: DropdownProps) => {
  const node = useRef(undefined);
  const wrapperRef = useRef(undefined);
  const { isIntersecting, checkNodeInViewport } = useOnScreen(wrapperRef, node);
  const [search, setSearch] = useState('');
  const [isActive, setActive] = useState(() => false);
  const [listSelectedItems, setListSelectedItems] = useState<DropdownItem[]>(
    []
  );
  const [listSelectedItemsAux, setListSelectedItemsAux] = useState<
    DropdownItem[]
  >([]);
  const [disableButton, setDisable] = useState(
    equals(listSelectedItems, listSelectedItemsAux)
  );

  useEffect(() => {
    setDisable(equals(listSelectedItems, listSelectedItemsAux));
  }, [listSelectedItems, listSelectedItemsAux]);

  useEffect(() => {
    if (isActive && shouldBreakOnViewport) {
      checkNodeInViewport();
    }
  }, [isActive]);

  useEffect(() => {
    const pageClickEvent = e => {
      if (node.current !== null && !node.current.contains(e.target)) {
        setActive(!isActive);
        setListSelectedItemsAux(clone(listSelectedItems));
      }
    };

    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }
    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isActive]);

  useEffect(() => {
    setListSelectedItemsAux(clone(listItems));
    setListSelectedItems(clone(listItems));
  }, [listItems]);

  const handleCheck = (value: string | number, isChecked: boolean) => {
    const items = listSelectedItemsAux.map((item: DropdownItem) => {
      item.isSelected = item.value === value ? isChecked : false;
      return item;
    });
    setListSelectedItemsAux(items);
  };

  const optionList = () => {
    return listSelectedItemsAux
      .filter((option: DropdownItem) =>
        option?.title?.toLowerCase().includes(search?.toLowerCase())
      )
      .map((option: DropdownItem) => (
        <div
          key={`${option.value}-${option.title}`}
          className={`flex items-center cursor-pointer mb-1 select-none px-5 ${
            option.isSelected && 'bg-neutrals-10'
          }`}
        >
          <CheckInput
            value={option.value}
            title={option.title}
            onChange={handleCheck}
            isOneSelectedOnly={isOneSelectedOnly}
            isChecked={option.isSelected}
          />
        </div>
      ));
  };

  const onClear = () => {
    if (isOneSelectedOnly) return;
    const items = listSelectedItemsAux.map(item => {
      item.isSelected = false;
      return item;
    });
    setListSelectedItemsAux(items);
  };

  const onSave = () => {
    const cloneArray = clone(listSelectedItemsAux);
    setListSelectedItems(cloneArray);
    setActive(false);
    onChange(cloneArray);
  };

  return (
    <div className={`relative inline-block ${className || ''}`} ref={node}>
      <button
        className={`flex h-9 px-4 py-2 items-center border ${
          isActive && !selected ? 'border-neutrals-60' : 'border-neutrals-40'
        } focus:outline-none rounded ${selected ? 'bg-primary-active' : ''} ${
          isDisabled ? 'cursor-not-allowed' : ''
        }`}
        onClick={() => setActive(!isActive)}
        disabled={isDisabled}
      >
        {icon && (
          <span
            className={`w-5 h-5 ${
              isActive ? 'fill-current' : 'fill-inactive'
            } ${selected ? 'color-primary-active' : ''}`}
          >
            {icon}
          </span>
        )}

        <span
          className={`mx-2.5 ${
            selected
              ? 'text-primary-60'
              : isActive
              ? 'text-neutral-100'
              : 'text-neutral-80'
          } font-aeonik font-medium text-sm`}
        >
          {selected || title}
        </span>
        <ArrowDropdown
          className={`w-5 h-5 transition duration-300 transform ${
            isActive ? 'rotate-180 fill-current' : '-rotate-80 fill-inactive'
          } ${selected ? 'color-primary-active' : ''}`}
        />
        {selected && loadingNewSelection && (
          <SpinnerLoader fillColor="#0000FF" />
        )}
      </button>
      <div
        ref={wrapperRef}
        className={`absolute bg-white origin-top mt-1.5 w-64 pt-4 rounded-lg-.5 shadow-bs-tertiary z-40
        ${isActive ? 'block' : 'hidden'} ${
          isIntersecting ? 'right-0' : 'left-0'
        }`}
      >
        <div className="flex justify-between mb-5 px-5">
          <span className="font-aeonik-medium">{title}</span>
          <Clear
            className="w-5 h-5 fill-inactive cursor-pointer"
            onClick={() => setActive(false)}
          />
        </div>

        {isSearchEnabled && (
          <div className="m-2">
            <Search
              customWidth="w-fit"
              onChange={inputValue => setSearch(inputValue)}
              initialSearchValue={search}
            />
          </div>
        )}
        <div className="flex flex-col max-h-60 overflow-auto pt-0">
          {loadingInitialData ? (
            <div className="flex h-60 w-full items-center justify-center">
              <Spinner size="normal" />
            </div>
          ) : listSelectedItemsAux?.length ? (
            optionList()
          ) : null}
        </div>
        {renderVariant ? null : (
          <div className={'flex justify-end items-center h-16 py-2 px-4'}>
            {!isOneSelectedOnly && (
              <button
                onClick={onClear}
                disabled={disableButton && loadingInitialData}
                className="font-aeonik-medium text-body-14 text-neutral-100 py-2 px-5 border border-neutral-40 rounded-.5 mr-1.5 focus:outline-none"
              >
                CLEAR
              </button>
            )}

            <button
              disabled={disableButton || loadingInitialData}
              onClick={onSave}
              className={`font-aeonik font-normal text-body-14 py-2 px-5 rounded-.5 focus:outline-none ${
                disableButton || loadingInitialData
                  ? 'text-neutrals-60 bg-neutrals-40'
                  : 'text-white bg-primary-60'
              }`}
            >
              SAVE
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
