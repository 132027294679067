import { ErrorMessage, Field, FormikErrors } from 'formik';
import { FunctionComponent } from 'react';

import styles from './styles.module.css';

interface InputNumberProps {
  title?: string;
  name: string;
  onBlur?: (event: InputChangePayload) => void;
  disabled?: boolean;
  fieldArrayIndex?: number;
  className?: string;
  theme?: InputTheme;
  placeholder?: string;
}

export enum InputTheme {
  MATERIAL,
  CUSTOM,
}

export interface formikFieldHelpers {
  setFieldValue: (
    field: string,
    value: string
  ) => Promise<FormikErrors<unknown>> | Promise<void>;
  handleBlur: (name: string) => void;
}

interface InputChangePayload {
  fieldValue: string;
  form: formikFieldHelpers;
  fieldArrayIndex: number;
}

const InputNumber: FunctionComponent<InputNumberProps> = ({
  title,
  name,
  onBlur,
  fieldArrayIndex = 0,
  disabled,
  className = '',
  placeholder= '',
  theme = InputTheme.MATERIAL,
}) => {
  const themeClass = {
    [InputTheme.MATERIAL]: {
      input: styles.textBox,
      bar: styles.bar,
      tag: styles.tag,
      error: styles.invalid,
    },
    [InputTheme.CUSTOM]: {
      input: 'shadow-bs-mvs-input',
      bar: '',
      tag: '',
      error: 'border-mvs-border-negative text-mvs-text-negative',
    },
  };

  const currentThemeStyle = themeClass[theme];

  return (
    <Field name={name}>
      {({ field, meta, form }) => (
        <div className={`relative ${disabled ? 'opacity-60' : ''} `}>
          {theme === InputTheme.CUSTOM && (
            <span
              className={`text-sm font-aeonik text-body-14 ${
                meta.error && meta.touched
                  ? 'text-mvs-text-negative'
                  : 'text-mvs-text-secondary'
              }`}
            >
              {title}
            </span>
          )}
          <input
            {...field}
            type="text"
            className={`${
              meta.touched && meta.error ? currentThemeStyle.error : ''
            } ${className} ${
              currentThemeStyle.input
            } shadow-inactive font-aeonik`}
            onBlur={event => {
              field.onBlur(event);
              const fieldValue = event.target.value.trim();
              onBlur &&
                onBlur({
                  fieldArrayIndex,
                  fieldValue,
                  form,
                });
            }}
            required
            placeholder={placeholder}
            disabled={disabled}
          />
          {theme === InputTheme.MATERIAL && (
            <>
              <span
                className={`${
                  meta.touched && meta.error ? currentThemeStyle.error : ''
                } ${currentThemeStyle.bar}`}
              ></span>
              <label className={`${currentThemeStyle.tag} font-aeonik`}>
                {title}
              </label>
              <ErrorMessage
                name={name}
                component="span"
                className="block text-xs text-danger-40"
              />
            </>
          )}
          {theme === InputTheme.CUSTOM && meta.touched && meta.error && (
            <span
              className={`${currentThemeStyle.error} text-xs text-mvs-text-negative`}
            >
              {meta.error}
            </span>
          )}
        </div>
      )}
    </Field>
  );
};

export default InputNumber;
