import Image from 'next/image';
import { FunctionComponent } from 'react';

import { Avatar, Tooltip } from 'components';

import { User } from 'types/user';

export interface Props {
  /**
   * Array of avatars
   */
  users: Array<User>;
  /**
   * Max number of avatars in group
   */
  max?: number;
}

const DEFAULT_MAX_AVATARS = 3;

export const AvatarGroup: FunctionComponent<Props> = ({
  users,
  max = DEFAULT_MAX_AVATARS,
}) => {
  if (max < 0) {
    max = DEFAULT_MAX_AVATARS;
  }

  const renderAvatars = () => {
    const avatarsArray = [];
    const elementsToIterate = users.length >= max ? max : users.length;

    for (const [index, user] of users.entries()) {
      if (index === elementsToIterate) break;

      avatarsArray.push(
        <div
          className={`group z-${users.length - index}0`}
          key={`${user}-${index}`}
        >
          <div data-tooltip-id={user?.username} data-tooltip-variant="light">
            <Avatar>
              {user?.src ? (
                <Image
                  className={`inline-block rounded-full ring-2 ring-white font-aeonik`}
                  src={user.src}
                  width={32}
                  height={32}
                  alt={user?.username ? user?.username?.[0] : ''}
                />
              ) : (
                <span className="text-xs">
                  {user?.username ? user?.username[0] : ''}
                </span>
              )}
            </Avatar>
          </div>
          <Tooltip id={user?.username} place="bottom">
            <div className="p-1 shadow-tooltip group-hover:opacity-100 text-xs font-aeonik rounded py-1 px-2.5">{user?.username}</div>
          </Tooltip>
        </div>
      );
    }

    return avatarsArray;
  };

  return (
    <div className="flex -space-x-1 overflow-hidden">{renderAvatars()}</div>
  );
};
