// Lib
import { MutableRefObject, ReactNode, useRef } from 'react';

export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  DARK = 'dark',
}

interface ToastProps {
  toastContext: MutableRefObject<ToastRef>;
  showToast: (data: ToastParams) => void;
  hideToast: () => void;
}

export interface ToastParams {
  message: string;
  type: ToastType;
  children?: ReactNode;
  hideToast?: boolean;
}

export interface ToastRef {
  showToast: (data: ToastParams) => void;
  hideToast: () => void;
}

const useToast = (): ToastProps => {
  const toastContext = useRef<ToastRef>();

  const showToast = (data: ToastParams): void => {
    toastContext.current?.showToast(data);
  };

  const hideToast = (): void => {
    toastContext.current?.hideToast();
  };

  return {
    toastContext,
    showToast,
    hideToast,
  };
};

export default useToast;
