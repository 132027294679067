import { ErrorMessage, Field } from 'formik';
import { FunctionComponent, FocusEvent } from 'react';

import styles from './styles.module.css';

export enum InputTheme {
  MATERIAL,
  CUSTOM,
}

interface TextAreaProps {
  name: string;
  title?: string;
  theme?: InputTheme;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  onBlur?(event: FocusEvent<HTMLTextAreaElement, Element>): void;
}

const TextArea: FunctionComponent<TextAreaProps> = ({
  title,
  name,
  theme = InputTheme.MATERIAL,
  placeholder = '',
  disabled,
  className = '',
  onBlur,
}) => {
  const themeClass = {
    [InputTheme.MATERIAL]: {
      input: styles.textArea,
      bar: styles.bar,
      tag: styles.tag,
      error: styles.invalid,
    },
    [InputTheme.CUSTOM]: {
      input:
        'shadow-bs-mvs-input border-mvs-border-lighter focus:border-mvs-border-main focus:outline-none resize-none',
      bar: '',
      tag: '',
      error: 'border-solid border-mvs-border-negative text-mvs-text-negative',
    },
  };

  const currentThemeStyle = themeClass[theme];

  return (
    <Field name={name}>
      {({ field, form, meta }) => (
        <div className={`relative w-full ${disabled ? 'opacity-60' : ''}`}>
          {theme === InputTheme.CUSTOM && (
            <span
              className={`text-sm font-aeonik text-body-14 ${
                meta.error && meta.touched
                  ? 'text-mvs-text-negative'
                  : 'text-mvs-text-secondary'
              }`}
            >
              {title}
            </span>
          )}

          <textarea
            {...field}
            placeholder={placeholder}
            className={`${currentThemeStyle.input} font-aeonik ${className}`}
            onBlur={e => {
              form.handleBlur(e);
              if (!meta.error && meta.touched) {
                onBlur && onBlur(e);
              }
            }}
          />

          {theme === InputTheme.MATERIAL && (
            <>
              <span
                className={`${
                  meta.touched && meta.error ? currentThemeStyle.error : ''
                } ${currentThemeStyle.bar}`}
              ></span>
              <label className={`${currentThemeStyle.tag} font-aeonik`}>
                {title}
              </label>
              <ErrorMessage
                name={name}
                component="span"
                className={`${currentThemeStyle.error} block text-xs text-danger-40`}
              />
            </>
          )}

          {theme === InputTheme.CUSTOM && meta.touched && meta.error && (
            <div
              className={`${currentThemeStyle.error} text-xs text-mvs-text-negative absolute mt-2`}
            >
              {meta.error}
            </div>
          )}
        </div>
      )}
    </Field>
  );
};

export default TextArea;
