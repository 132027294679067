import { Currencies } from 'utils/enums';

const MOCK_INITIAL_CONFIG = {
  menu: [
    {
      id: 'dashboard',
      label: 'Dashboard',
      path: '/',
      disabled: false,
      icon: {
        className: '',
        icon: 'exit-to-app',
      },
    },
  ],
  currency_formats: {
    __USD__: {
      name: 'US Dollar',
      currency: 'USD %s.2',
      prefix: '$ %s.2',
    },
  },
  currency_formats_without_decimals: {
    __USD__: {
      name: 'US Dollar',
      currency: 'USD %s.0',
      prefix: '$ %s.0',
    },
  },
  weight_formats: {
    __LB__: {
      name: 'pound',
      prefix: '%s.1 lb',
      unit_system: 'US',
    },
  },
  length_formats: {
    __CM__: {
      name: 'centimeter',
      prefix: '%s.1 cm',
      unit_system: 'SI',
    },
    __M__: {
      name: 'meter',
      prefix: '%s.1 m',
      unit_system: 'SI',
    },
    __IN__: {
      name: 'inch',
      prefix: '%s.2 in',
      unit_system: 'US',
    },
    __FT__: {
      name: 'feet',
      prefix: '%s.1 ft',
      unit_system: 'US',
    },
  },
};

export const currencyFormat =
  MOCK_INITIAL_CONFIG.currency_formats[Currencies.USD];

export const APPLICATION_DATA = {
  menu: [
    {
      id: 'orders',
      label: 'Orders',
      disabled: false,
      expand: true,
      children: [
        {
          id: 'orders',
          label: 'Orders',
          path: '/orders',
          disabled: false,
          icon: {
            className: '',
            icon: 'local-mall',
          },
        },
      ],
    },
    {
      id: 'inventory',
      label: 'Inventory',
      expand: true,
      disabled: false,
      children: [
        {
          id: 'inventory_list',
          label: 'Inventory List',
          path: '/inventory',
          disabled: false,
          icon: {
            className: '',
            icon: 'inventory-2',
          },
        },
      ],
    },
    {
      id: 'warehouse',
      label: 'Warehouse',
      expand: true,
      disabled: false,
      children: [
        {
          id: 'locations',
          label: 'Locations',
          path: '/locations',
          disabled: false,
          icon: {
            className: '',
            icon: 'place',
          },
        },
      ],
    },
    {
      id: 'catalog',
      label: 'Catalog',
      expand: true,
      disabled: false,
      children: [
        {
          id: 'products',
          label: 'Products',
          path: '/catalog',
          disabled: false,
          icon: {
            className: '',
            icon: 'devices-other',
          },
        },
      ],
    },
    {
      id: 'sales-channel',
      label: 'Sales Channels',
      expand: true,
      visible: true,
      allowed: true,
      children: [
        {
          id: 'channels',
          label: 'Sales Channels',
          path: '/sales-channel',
          disabled: false,
          icon: {
            className: '',
            icon: 'hub',
          },
        },
      ],
    },
    {
      id: 'team',
      label: 'Team',
      expand: true,
      visible: true,
      allowed: true,
      children: [
        {
          id: 'team',
          label: 'Team',
          path: '/team',
          disabled: false,
          icon: {
            className: '',
            icon: 'person-pin',
          },
        },
      ],
    },
    // {
    //   id: 'integrations',
    //   label: 'Integrations',
    //   expand: true,
    //   visible: true,
    //   allowed: true,
    //   children: [
    //     {
    //       id: 'integrations',
    //       label: 'Integrations',
    //       path: '/integrations',
    //       disabled: false,
    //       icon: {
    //         className: '',
    //         icon: 'integrations',
    //       },
    //     },
    //   ],
    // },
  ],
  currency_formats: {
    __EUR__: {
      name: 'Euro',
      currency: 'EUR %s.2',
      prefix: '€ %s.2',
    },
    __USD__: {
      name: 'US Dollar',
      currency: 'USD %s.2',
      prefix: '$ %s.2',
    },
    __ARS__: {
      name: 'Argentine peso',
      currency: 'ARS %s.2',
      prefix: '$ %s.2',
    },
    __AUD__: {
      name: 'Australian dollar',
      currency: 'AUD %s.2',
      prefix: '$ %s.2',
    },
    __BRL__: {
      name: 'Brazilian real',
      currency: 'BRL %s.2',
      prefix: 'R$ %s.2',
    },
    __CAD__: {
      name: 'Canadian dollar',
      currency: 'CAD %s.2',
      prefix: '$ %s.2',
    },
    __CLP__: {
      name: 'Chilean peso',
      currency: 'CLP %s.2',
      prefix: '$ %s.2',
    },
    __CNY__: {
      name: 'Chinese yuan',
      currency: 'CNY %s.2',
      prefix: '¥ %s.2',
    },
    __COP__: {
      name: 'Colombian peso',
      currency: 'COP %s.2',
      prefix: '$ %s.2',
    },
    __CUP__: {
      name: 'Cuban peso',
      currency: 'CUP %s.2',
      prefix: '$ %s.2',
    },
    __GBP__: {
      name: 'British pound',
      currency: 'GBP %s.2',
      prefix: '£ %s.2',
    },
    __HKD__: {
      name: 'Hong Kong dollar',
      currency: 'HKD %s.2',
      prefix: '$ %s.2',
    },
    __INR__: {
      name: 'Indian rupee',
      currency: 'INR %s.2',
      prefix: '₹ %s.2',
    },
    __ILS__: {
      name: 'Israeli new shekel',
      currency: 'ILS %s.2',
      prefix: '₪ %s.2',
    },
    __JPY__: {
      name: 'Japanese yen',
      currency: 'JPY %s.2',
      prefix: '¥ %s.2',
    },
    __MXN__: {
      name: 'Mexican peso',
      currency: 'MXN %s.2',
      prefix: '$ %s.2',
    },
    __NZD__: {
      name: 'Cook Islands dollar',
      currency: 'NZD %s.2',
      prefix: '$ %s.2',
    },
    __PEN__: {
      name: 'Peruvian sol',
      currency: 'PEN %s.2',
      prefix: 'S/. %s.2',
    },
    __RUB__: {
      name: 'Russian ruble',
      currency: 'RUB %s.2',
      prefix: '₽ %s.2',
    },
    __SGD__: {
      name: 'Singapore dollar',
      currency: 'SGD %s.2',
      prefix: '$ %s.2',
    },
    __ZAR__: {
      name: 'South African rand',
      currency: 'ZAR %s.2',
      prefix: 'R %s.2',
    },
    __UYU__: {
      name: 'Uruguayan peso',
      currency: 'UYU %s.2',
      prefix: '$ %s.2',
    },
    __VEF__: {
      name: 'Bolívar',
      currency: 'VEF %s.2',
      prefix: 'Bs %s.2',
    },
  },
  weight_formats: {
    __MG__: {
      name: 'miligram',
      prefix: '%s.1 mg',
      unit_system: 'SI',
    },
    __G__: {
      name: 'gram',
      prefix: '%s.1 g',
      unit_system: 'SI',
    },
    __KG__: {
      name: 'kilogram',
      prefix: '%s.2 kg',
      unit_system: 'SI',
    },
    __T__: {
      name: 'tonne',
      prefix: '%s.1 t',
      unit_system: 'SI',
    },
    __OZ__: {
      name: 'ounce',
      prefix: '%s.1 oz',
      unit_system: 'US',
    },
    __LB__: {
      name: 'pound',
      prefix: '%s.2 lb',
      unit_system: 'US',
    },
    __UKT__: {
      name: 'UK tonne',
      prefix: '%s.1 tonne',
      unit_system: 'US',
    },
    __UST__: {
      name: 'US tonne',
      prefix: '%s.1 tonne',
      unit_system: 'US',
    },
  },
  length_formats: {
    __CM__: {
      name: 'centimeter',
      prefix: '%s.1 cm',
      unit_system: 'SI',
    },
    __M__: {
      name: 'meter',
      prefix: '%s.1 m',
      unit_system: 'SI',
    },
    __IN__: {
      name: 'inch',
      prefix: '%s.2 in',
      unit_system: 'US',
    },
    __FT__: {
      name: 'feet',
      prefix: '%s.1 ft',
      unit_system: 'US',
    },
  },
};
