import { gql } from '@apollo/client';

const COMPANIES_DROPDOWN_QUERY = gql`
  query Companies {
    companies {
      value: id
      title: name
    }
  }
`;

export default COMPANIES_DROPDOWN_QUERY;
