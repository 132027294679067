import { FunctionComponent, ReactElement } from 'react';

const sizes = {
  small: {
    height: 'h-6',
    width: 'w-6',
  },
  normal: {
    height: 'h-8',
    width: 'w-8',
  },
  big: {
    height: 'h-10',
    width: 'w-10',
  },
  extraBig: {
    height: 'h-14',
    width: 'w-14',
  },
};

type AvatarSizes = keyof typeof sizes;

export interface AvatarProps {
  className?: string;
  size?: AvatarSizes;
  children?: ReactElement;
}

const Avatar: FunctionComponent<AvatarProps> = ({
  className = 'bg-primary-avatar active-primary-avatar ring-transparent',
  size = 'normal',
  children,
}: AvatarProps) => {
  return (
    <div
      data-testid="avatar"
      className={`${className} ${sizes[size].height} ${sizes[size].width} ring-1 rounded-full flex align-center justify-center items-center`}
    >
      {children}
    </div>
  );
};

export default Avatar;
