const INITIAL_SEARCH_VALUE = '';
const INITIAL_TABLE_PAGE = 1;
const DEFAULT_TOTAL_PAGES = 1;
const INITIAL_TABLE_ROWS = 50;
const MINIMUM_WAIT_TIME = 400;

export {
  INITIAL_SEARCH_VALUE,
  INITIAL_TABLE_PAGE,
  DEFAULT_TOTAL_PAGES,
  INITIAL_TABLE_ROWS,
  MINIMUM_WAIT_TIME,
};
