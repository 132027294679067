import { RefObject, useState } from "react";

const useOnScreen = (childNode: RefObject<HTMLElement>, parentNode: RefObject<HTMLElement>) => {
  const [ isIntersecting, setIntersecting ] = useState(false);

  const checkNodeInViewport = () => {
    if(childNode) {
      const { width } = childNode.current.getBoundingClientRect();
      const { left } = parentNode.current.getBoundingClientRect();
      const windowWidth = window.innerWidth || document.documentElement.clientWidth;
      
      setIntersecting(left + width >= windowWidth);
    }
  };

  return { isIntersecting, checkNodeInViewport };
};

export default useOnScreen;
