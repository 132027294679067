import { ErrorMessage, Field } from 'formik';
import { ChangeEvent, FunctionComponent } from 'react';

import { InputTheme } from 'components/InputText';

import styles from '../InputText/styles.module.css';

export interface ListItems {
  value: string;
  title: string;
}
export interface SelectProps {
  /**
   * Description text for the button
   */
  label?: string;
  /**
   * List of items to be displayed in the dropdown
   */
  list?: Array<ListItems>;
  /**
   * Boolean expresion to disable button
   */
  isDisabled?: boolean;
  /**
   * Value to assign at SelectField
   */
  value?: string;
  /**
   * Input name
   */
  name: string;
  /**
   * Title/Name of the component
   */
  title?: string;
  /**
   * Set the theme styles
   */
  theme?: InputTheme;
  /**
   * Function to handle change
   */
  onChange?(event: ChangeEvent<HTMLSelectElement>): void;
}

const InputSelect: FunctionComponent<SelectProps> = ({
  label = 'Status',
  list = [],
  value,
  isDisabled,
  name,
  title,
  theme = InputTheme.MATERIAL,
  onChange,
}: SelectProps) => {
  const themeClass = {
    [InputTheme.MATERIAL]: {
      input: styles.textBox,
      bar: styles.bar,
      tag: styles.tag,
      label: '',
      error: styles.invalid,
    },
    [InputTheme.CUSTOM]: {
      input: 'border border-mvs-border-lighter',
      bar: '',
      tag: '',
      label: 'hidden',
      error: 'border-solid border-mvs-border-negative text-mvs-text-negative',
    },
  };

  const currentThemeStyle = themeClass[theme];

  return (
    <Field name={name}>
      {({ field, meta, form }) => (
        <div className="relative">
          <label
            className={`absolute top-2 left-3 text-neutrals-80 text-xs ${currentThemeStyle.label}`}
          >
            {label}
          </label>
          {theme === InputTheme.CUSTOM && (
            <span
              className={`text-sm font-aeonik text-body-14 ${
                meta.error && meta.touched && !meta.value
                  ? 'text-mvs-text-negative'
                  : 'text-mvs-text-secondary'
              }`}
            >
              {title}
            </span>
          )}
          <select
            {...field}
            disabled={isDisabled}
            className={`${
              value || field.value
                ? 'text-neutrals-100 pt-6'
                : 'text-neutrals-80'
            } ${
              !meta.value && meta.error && meta.touched
                ? currentThemeStyle.error
                : currentThemeStyle.input
            } ${ isDisabled ? 'pointer-events-none opacity-40' : ''}
           bg-white p-3 rounded-lg shadow-bs-mvs-input pt-3 flex justify-between items-center w-full h-14 text-body-14 rounded-t focus:outline-none focus:ring-transparent active:border-transparent`}
            onChange={e => {
              form.handleChange(e);
              onChange && onChange(e);
            }}
          >
            {!value && <option value="">{label}</option>}
            {list.map(option => (
              <option
                key={option.value}
                value={option.value}
                className="border-none bg-white outline-none focus:bg-transparent hover:bg-transparent"
              >
                {option.title}
              </option>
            ))}
          </select>
          {theme === InputTheme.MATERIAL && (
            <>
              <span
                className={`${
                  meta.touched && meta.error ? currentThemeStyle.error : ''
                } ${currentThemeStyle.bar}`}
              ></span>
              <label className={`${currentThemeStyle.tag} font-aeonik`}>
                {title}
              </label>
              <ErrorMessage
                name={name}
                component="span"
                className={`${currentThemeStyle.error} block text-xs text-danger-40`}
              />
            </>
          )}
          {theme === InputTheme.CUSTOM && meta.error && meta.touched && !meta.value && (
            <span
              className={`${currentThemeStyle.error} text-xs text-mvs-text-negative`}
            >
              {meta.error}
            </span>
          )}
        </div>
      )}
    </Field>
  );
};

export default InputSelect;
