import { FunctionComponent } from 'react';

import { NoResultsIcon } from 'components/Icons/NoResults';

interface NoResultProps {
  title: string;
  description: string;
}

const NoResults: FunctionComponent<NoResultProps> = ({
  title,
  description,
}) => {
  return (
    <div className="bg-neutrals-10 w-full h-full flex justify-center items-center py-16">
      <div className="flex flex-col items-center justify-center w-55p">
        <NoResultsIcon />
        <p className="text-headline-18 font-aeonik-medium mx-2 pb-2 pt-8 text-neutrals-100">
          {title}
        </p>
        <p className="text-body-16 leading-5 mx-6 text-center text-neutrals-80">
          {description}
        </p>
      </div>
    </div>
  );
};

export default NoResults;
