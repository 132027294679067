import { init, setUserId, track } from '@amplitude/analytics-browser';

import { TrackingService } from 'types/analytics';

class AmplitudeService implements TrackingService {
  private apiKey: string | undefined;

  constructor(apiKey: string) {
    this.apiKey = apiKey;

    if (this.apiKey && typeof window !== 'undefined') {
      init(apiKey, {
        defaultTracking: false,
      });
    }
  }

  trackEvent = (
    eventName: string,
    eventProperties?: Record<string, unknown>
  ) => {
    if (this.apiKey && typeof window !== 'undefined') {
      track(eventName, eventProperties);
    } else {
      console.warn(
        `Tracking event '${eventName}' ignored: No API key provided.`
      );
    }
  };

  setUser = (userId: string | undefined) => {
    if (this.apiKey && typeof window !== 'undefined') {
      setUserId(userId);
    } else {
      console.warn(`Tracking event 'setUserId' ignored: No API key provided.`);
    }
  };
}

export default AmplitudeService;
