import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';

import { useTracking } from './useTracking';

const DEFAULT_AVATAR_URL =
  'https://cdn.dev.mvnifest.co/attachments/User/avatar.png';

const useUserData = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const { data: session } = useSession();
  const { setUser } = useTracking();

  useEffect(() => {
    if (session) {
      const formatUser = {
        username: session.user?.name,
        email: session.user?.email,
        src: session.user?.image || DEFAULT_AVATAR_URL,
      };
      setUser(session.user.email);
      setCurrentUser(formatUser);
    }
  }, [session]);

  return { currentUser, usersList: [] };
};

export default useUserData;
