export enum Weights {
  MG = '__MG__',
  G = '__G__',
  KG = '__KG__',
  T = '__T__',
  OZ = '__OZ__',
  LB = '__LB__',
  UKT = '__UKT__',
  UST = '__UST__',
}
