// Lib
import {
  FunctionComponent,
  MutableRefObject,
  useEffect,
  useId,
  useImperativeHandle,
  useState,
} from 'react';

import { ToastParams, ToastRef, ToastType } from 'hooks/useToast';

import Check from 'assets/icons/check.svg';
import CloseSmall from 'assets/icons/close-small.svg';
import Close from 'assets/icons/close.svg';
import Exclamation from 'assets/icons/exclamation.svg';

export interface ToastProps {
  alwaysInScreen?: boolean;
  centerToast?: boolean;
  minWidth?: number | string;
  relativeToWindow?: boolean;
  transitionDuration?: number;
  toastContext: MutableRefObject<ToastRef>;
  toastDuration?: number;
}

interface ToastScheme {
  box: string;
  iconClass?: string;
  icon?: JSX.Element;
  close: string;
}

const ToastScheme: Record<ToastType, ToastScheme> = {
  [ToastType.SUCCESS]: {
    box: `bg-success-10 text-success-80 border-success-80`,
    iconClass: `bg-success-80`,
    close: `text-success-80`,
    icon: <Check className="text-white fill-current scale-[0.9]" />,
  },
  [ToastType.ERROR]: {
    box: `bg-danger-10 text-danger-80 border-danger-80`,
    iconClass: `bg-danger-80`,
    close: `text-danger-80`,
    icon: <Exclamation className="text-white fill-current scale-[1.3]" />,
  },
  [ToastType.DARK]: {
    box: `bg-neutrals-100 text-white border-neutrals-80`,
    close: `text-white`,
  },
};

const Toast: FunctionComponent<ToastProps> = ({
  alwaysInScreen = false,
  centerToast = false,
  minWidth = 400,
  relativeToWindow = true,
  transitionDuration = 400,
  toastContext,
  toastDuration = 5000,
}: ToastProps) => {
  const [isToastLoaded, setIsToastLoaded] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [currentToast, setCurrentToast] = useState<ToastParams>(null);
  const toastId = useId();

  const showToast = (params: ToastParams) => {
    setCurrentToast(params);
    setIsToastVisible(true);
    setIsToastLoaded(true);
  };

  const hideToast = () => {
    setIsToastVisible(false);
    setIsToastLoaded(false);
  };

  useImperativeHandle(
    toastContext,
    () => {
      return {
        showToast,
        hideToast,
      };
    },
    []
  );

  useEffect(() => {
    if (!alwaysInScreen) {
      const timeoutId = setTimeout(() => {
        setIsToastLoaded(false);
      }, toastDuration);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [currentToast]);

  const onToastTransitionEnd = ({ target, propertyName }) => {
    if (propertyName === 'opacity' && target.style.opacity === '0') {
      hideToast();
    }
  };

  if (!isToastVisible) return null;

  return (
    <div
      id={`toast-${toastId}`}
      className={`${
        relativeToWindow ? `absolute` : `fixed`
      }
        ${
          currentToast?.children || centerToast
            ? 'mx-auto right-0 left-0'
            : 'left-10'
        }
        z-10 bottom-5 flex items-center p-4 mb-4 w-full max-w-xs bg-white rounded-lg border transform-gpu ${
          ToastScheme[currentToast?.type]?.box
        }`}
      role="alert"
      onTransitionEnd={onToastTransitionEnd}
      style={{
        transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`,
        transform: !isToastLoaded ? 'translateY(20px)' : 'none',
        opacity: !isToastLoaded ? 0 : 1,
        minWidth,
      }}
    >
      {!currentToast?.children && ToastScheme[currentToast?.type]?.icon ? (
        <div
          className={`inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-white rounded-full ${
            ToastScheme[currentToast?.type]?.iconClass
          }`}
        >
          {ToastScheme[currentToast?.type]?.icon}
        </div>
      ) : null}
      <div className="flex justify-center w-full">
        <div
          className={`text-base ${
            currentToast?.children ? '' : 'ml-3 font-aeonik'
          }`}
        >
          {currentToast?.message}
        </div>
        <div>{currentToast?.children}</div>
      </div>
      <button
        type="button"
        className={`${
          currentToast?.children ? 'ml-3' : 'ml-auto h-8 w-8'
        } -mx-1.5 -my-1.5 text-gray-400 hover:text-gray-900 rounded-lg p-1.5 inline-flex`}
        aria-label="Close"
        onClick={() => setIsToastLoaded(false)}
      >
        {currentToast?.children ? (
          <CloseSmall className={ToastScheme[currentToast?.type]?.close} />
        ) : (
          <Close className={ToastScheme[currentToast?.type]?.close} />
        )}
      </button>
    </div>
  );
};

export default Toast;
