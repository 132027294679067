import Cookie from 'js-cookie';

const COOKIES: Record<string, string> = {
  authorization: 'authorization',
  brandId: 'brandId',
  companyId: 'companyId',
};

interface Cookies {
  getCookie: (name: string) => string;
  setCookie: (name: string, value: string) => void;
  removeCookie: (name: string) => void;
  COOKIES?: Record<string, string>;
}

const getCookie = (name: string): string => {
  return Cookie.get(name);
};

const setCookie = (name: string, value: string) => {
  Cookie.set(name, value);
};

const removeCookie = (name: string) => {
  Cookie.remove(name);
};

const useCookies = (): Cookies => {
  return { getCookie, setCookie, removeCookie, COOKIES };
};

export { COOKIES, getCookie, useCookies };
