import { AppProps } from 'next/app';
import { isEmpty } from 'ramda';
import React from 'react'
import { FunctionComponent, useEffect } from 'react';

import { AuthLayout, MainLayout } from 'components/Layout';

import { useAppDispatch, useAppSelector } from 'hooks';
import Bugsnag from 'lib/bugsnag'
import { sessionActions } from 'store/slices/sessionSlice';

const LayoutResolver: FunctionComponent<AppProps> = ({
  Component,
  ...pageProps
}: AppProps) => {
  const dispatch = useAppDispatch();
  const authorization = useAppSelector(state => state.session.authorization);
  const Layout = !isEmpty(authorization) ? MainLayout : AuthLayout;

  const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

  useEffect(() => {
    dispatch(sessionActions.checkAuth());
  }, []);

  return (
    <Layout {...pageProps}>
      <ErrorBoundary>
        <Component {...pageProps} />
      </ErrorBoundary>
    </Layout>
  );
};

export default LayoutResolver;
