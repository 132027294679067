import { FunctionComponent } from 'react';

import { Button } from 'components';

import CenteredModal from 'components/Modals/CenteredModal';

import ErrorIcon from 'assets/icons/error-icon.svg';

interface ErrorModalProps {
  buttonText: string;
  title: string;
  subtitle: string;
  details?: JSX.Element;
  onCancel: () => void;
  onConfirm: () => void;
}

const ErrorModal: FunctionComponent<ErrorModalProps> = ({
  buttonText,
  subtitle,
  title,
  details,
  onCancel,
  onConfirm,
}) => {
  return (
    <CenteredModal isSmall hasVariableHeight onCancel={onCancel}>
      <div className="py-1 px-0.5">
        <div className="flex justify-items-center items-center mb-3.5">
          <div className="mr-2.5">
            <ErrorIcon />
          </div>
          <h2 className="text-headline-20 leading-7 text-neutrals-100 font-aeonik-medium">
            {title}
          </h2>
        </div>
        <p className="mb-6 text-body-14 leading-5 font-aeonik text-neutrals-80">
          {subtitle}
        </p>
        {details && (
          <>
            <p className="mb-2 text-body-14 leading-5 font-aeonik font-bold">
              Details
            </p>
            <p className="mb-6 text-body-14 leading-5 font-aeonik text-neutrals-80">
              {details}
            </p>
          </>
        )}
        <div className="flex justify-end">
          <Button handleClick={onConfirm}>{buttonText}</Button>
        </div>
      </div>
    </CenteredModal>
  );
};

export default ErrorModal;
