import BugsnagPerformance from '@bugsnag/browser-performance'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const BUGSNAG_API_KEY = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || '';

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({ apiKey: BUGSNAG_API_KEY })

export default Bugsnag;