import React, { FunctionComponent, ReactNode } from 'react';

import NoItemsIcon from 'assets/icons/no-items.svg';

interface EmptyItemsProps {
  title?: string;
  subtitle?: string;
  icon?: ReactNode;
}

const EmptyItems: FunctionComponent<EmptyItemsProps> = ({
  title,
  subtitle,
  icon = <NoItemsIcon />,
}) => {
  return (
    <div className="w-full flex justify-center items-center relative">
      {icon}
      <div className="absolute top-[12.5rem] text-center">
        {title && <h3 className="text-lg font-bold mb-3">{title}</h3>}
        {subtitle && (
          <h5 className="text-sm text-mvs-text-secondary font-light">
            {subtitle}
          </h5>
        )}
      </div>
    </div>
  );
};

export default EmptyItems;
