import { FunctionComponent, useEffect } from 'react';

import { Header, Navbar, Spinner } from 'components';

import { useAppSelector, useAuthentication } from 'hooks';

import { FeatureFlag } from 'types/featureFlags';

interface LayoutProps {
  children?: React.ReactNode;
  featureFlags: FeatureFlag[];
  pageProps?: Record<string, unknown>;
}

const MainLayout: FunctionComponent<LayoutProps> = ({
  children,
  featureFlags,
  pageProps,
}) => {
  const sessionLoading = useAppSelector(state => state.session.sessionLoading);
  const menuItems = useAppSelector(state => state.config.menu);

  const { handleValidateSession } = useAuthentication({
    featureFlags,
  });

  useEffect(() => {
    handleValidateSession();
  }, []);

  if (sessionLoading) {
    return (
      <div className="flex w-full h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="h-screen w-screen">
      <Header />
      <div className="flex h-content-container w-screen relative">
        <Navbar items={menuItems} />
        <main
          className={`flex flex-1 flex-col w-screen pb-0 overflow-auto ${
            pageProps?.variant === 'compact' ? '' : 'px-7 pt-7 bg-gray-50'
          }`}
        >
          {children}
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
