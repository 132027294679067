import { FunctionComponent, ReactNode } from 'react';

export interface ButtonProps {
  handleClick: () => void;
  children: ReactNode;
  isDisabled?: boolean;
  isCancel?: boolean;
}

const Button: FunctionComponent<ButtonProps> = ({
  handleClick,
  children,
  isDisabled,
  isCancel,
}) => {
  return (
    <button
      className={`${
        isDisabled
          ? 'bg-neutrals-40 text-neutrals-80 cursor-default'
          : isCancel
          ? 'text-icons border cursor-pointer'
          : 'bg-primary-60 text-white cursor-pointer'
      } flex h-9 py-5 px-7 text-sm font-aeonik-medium items-center justify-center rounded-.5`}
      type="button"
      disabled={isDisabled}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default Button;
