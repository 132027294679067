import {
  DocumentNode,
  OperationVariables,
  SubscriptionHookOptions,
  useSubscription,
} from '@apollo/client';
import { useEffect, useState } from 'react';

interface DataSubscriptionProps {
  variables: OperationVariables;
  subscription?: DocumentNode;
  options?: SubscriptionHookOptions;
}

const useDataSubscription = <T>({
  subscription,
  variables,
  options = {},
}: DataSubscriptionProps) => {
  const { data, error, loading } = useSubscription<T>(subscription, {
    variables,
    ...options,
  });
  const [subscriptionData, setSubscriptionData] = useState<T>(null);

  useEffect(() => {
    if (data) {
      setSubscriptionData(data);
    }
  }, [data]);

  return { subscriptionData, error, loading };
};

export default useDataSubscription;
