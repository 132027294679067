export enum Currencies {
  USD = '__USD__',
  EUR = '__EUR__',
  ARS = '__ARS__',
  AUD = '__AUD__',
  BRL = '__BRL__',
  CAD = '__CAD__',
  CLP = '__CLP__',
  CNY = '__CNY__',
  COP = '__COP__',
  CUP = '__CUP__',
  GBP = '__GBP__',
  HKD = '__HKD__',
  INR = '__INR__',
  ILS = '__ILS__',
  JPY = '__JPY__',
  MXN = '__MXN__',
  NZD = '__NZD__',
  PEN = '__PEN__',
  RUB = '__RUB__',
  SGD = '__SGD__',
  ZAR = '__ZAR__',
  UYU = '__UYU__',
  VEF = '__VEF__',
}
