import { FunctionComponent, ReactElement } from 'react';

import useKeyPress from 'hooks/useKeyPress';

interface CenteredModalProps {
  customZIndex?: string;
  children: ReactElement;
  hasVariableHeight?: boolean;
  isSmall?: boolean;
  isWide?: boolean;
  isMedium?: boolean;
  onCancel: () => void;
}

const CenteredModal: FunctionComponent<CenteredModalProps> = ({
  customZIndex = 'z-[31]',
  children,
  hasVariableHeight,
  isSmall,
  isWide,
  isMedium,
  onCancel,
}) => {
  useKeyPress('Escape', onCancel);

  return (
    <div
      className={`flex h-full w-full justify-center items-center inset-0 fixed ${customZIndex} `}
    >
      <div
        className="h-full w-fill fixed inset-0 z-20 bg-secondary-opacity"
        onClick={onCancel}
      ></div>
      <div
        className={`flex flex-col absolute bg-white rounded-lg opacity-100 z-30 ${
          isSmall
            ? hasVariableHeight
              ? 'min-h-60 p-7 w-[var(--modal-width,25rem)]'
              : 'p-7 h-[var(--modal-height,15rem)] w-[var(--modal-width,25rem)]'
            : isWide
            ? 'w-55p h-92p left-25p top-4p px-10 py-8'
            : isMedium
            ? 'w-[var(--modal-width,35rem)] h-[var(--modal-height,21rem)] p-[var(--modal-padding,2.5rem)]'
            : 'w-42p h-92p left-29p top-4p px-10 py-8'
        } `}
      >
        {children}
      </div>
    </div>
  );
};

export default CenteredModal;
