import { FunctionComponent, MouseEvent } from 'react';

import AddTask from 'assets/icons/add-task.svg';
import ArrowLeft from 'assets/icons/arrow-left.svg';
import ArrowRight from 'assets/icons/arrow-right.svg';
import Inventory from 'assets/icons/inventory-2.svg';
import MoreVert from 'assets/icons/more-vert.svg';
import Save from 'assets/icons/save-alt.svg';

const CIRCLE_BUTTON_ICONS = {
  arrowRight: <ArrowRight />,
  arrowLeft: <ArrowLeft />,
  save: <Save />,
  more: <MoreVert />,
  inventory: <Inventory />,
  task: <AddTask />,
};

type SupportedIcons = keyof typeof CIRCLE_BUTTON_ICONS;

const SIZES = {
  small: {
    height: 'h-8.5',
    width: 'w-8.5',
  },
  normal: {
    height: 'h-10.5',
    width: 'w-10.5',
  },
  big: {
    height: 'h-12',
    width: 'w-12',
  },
};

type SupportedSizes = keyof typeof SIZES;

export interface CircleButtonProps {
  /**
   * Icon name to be displayed inside the button
   */
  icon: SupportedIcons;
  /**
   * Button size to be displayed
   */
  size?: SupportedSizes;
  /**
   * Boolean expresion to disable button
   */
  isDisabled?: boolean;
  /**
   * Classname for align component
   */
  className?: string;
  /**
   * Callback which will be callled after clicking any list item
   */
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  /**
   * Children
   */
  children?: React.ReactNode;
}

const CircleButton: FunctionComponent<CircleButtonProps> = ({
  isDisabled = false,
  icon,
  className,
  onClick,
  size = 'small',
}: CircleButtonProps) => {
  return (
    <button
      className={`${SIZES[size].height} ${
        SIZES[size].width
      } border border-neutral-40 rounded-full flex items-center justify-center fill-current text-neutral-80 focus:outline-none ${
        isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
      } ${className} `}
      disabled={isDisabled}
      onClick={onClick}
    >
      {CIRCLE_BUTTON_ICONS[icon]}
    </button>
  );
};

export default CircleButton;
