import { useContext } from 'react';

import { TrackingContext } from 'context/TrackingContext';

import { TrackingService } from 'types/analytics';

export const useTracking = (): TrackingService => {
  const context = useContext(TrackingContext);
  if (!context) {
    throw new Error('useTracking must be used within a TrackingProvider');
  }
  return context;
};
