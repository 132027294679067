import { createContext, ReactNode } from 'react';

import createTrackingService from 'lib/analytics/trackingFactory';

import { TrackingService } from 'types/analytics';

export const TrackingContext = createContext<TrackingService | undefined>(
  undefined
);

export const TrackingProvider = ({ children }: { children: ReactNode }) => {
  const trackingService = createTrackingService();
  return (
    <TrackingContext.Provider value={trackingService}>
      {children}
    </TrackingContext.Provider>
  );
};
