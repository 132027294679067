import {
  Fragment,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTable, useRowSelect } from 'react-table';

import { ErrorText, checkColumnPush } from 'components';

import NoResults from 'components/NoResults';

import { NOT_FOUND_MESSAGES } from 'utils/constants/noResultsFound';
import { OrdersStatus } from 'utils/enums';

import ArrowUp from 'assets/icons/arrow-up.svg';
import ArrowUpward from 'assets/icons/arrow-upward.svg';

interface RowConfigProps {
  data: RowsProps;
  medatadata?: RowMetaDataProps;
}

export enum SortMethod {
  ASC = 'ASC',
  DESC = 'DESC',
}

interface SortByColumn {
  isActive: boolean;
  method: SortMethod;
  isSortable: boolean;
  filterName: string;
}

export interface Column {
  Header: ReactNode;
  accessor: string;
  sortByColumn?: SortByColumn;
  tooltipMessage?: string;
  headerType?: HeaderCellType;
}

export enum HeaderCellType {
  NUMBER,
}

export interface RowsProps {
  [key: string]: unknown;
}

export interface RowMetaDataProps {
  className: string;
}

export type RowMode = 'compact' | 'default';

export interface TableProps {
  columns: Column[];
  data: Array<RowsProps | RowConfigProps>;
  subRowKey?: string;
  className?: string;
  errorColumn?: string;
  selectRows?: boolean;
  scrollOnUpdate?: boolean;
  hideColumn?: string;
  variant?: 'default' | 'dark';
  setSelectedRows?([]): void;
  onClickColumn?: (index: number) => void;
  setHeaders?: (value: SetStateAction<Column[]>) => void;
  setSortFilter?: (value: SetStateAction<string>) => void;
  setSortMethod?: (value: SetStateAction<SortMethod>) => void;
  noResultsTemplate?: ReactNode;
  rowMode?: RowMode;
}

const Table: FunctionComponent<TableProps> = ({
  columns,
  data,
  selectRows = false,
  scrollOnUpdate = true,
  className = '',
  errorColumn = '',
  hideColumn,
  subRowKey,
  onClickColumn,
  setSelectedRows,
  setHeaders,
  setSortFilter,
  setSortMethod,
  variant = 'default',
  noResultsTemplate,
  rowMode = 'default',
}) => {
  const { getTableProps, headers, rows, prepareRow, selectedFlatRows } =
    useTable({ columns, data }, useRowSelect, hooks =>
      selectRows ? checkColumnPush(hooks) : null
    );
  const [rowsResults, setRowsResults] = useState(null);
  const [areThereSubRows, setAreThereSubRows] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    if (setSelectedRows) {
      setSelectedRows(selectedFlatRows.map(row => row.original));
    }
  }, [selectedFlatRows]);

  useEffect(() => {
    if (scrollOnUpdate && data && data.length) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [data]);

  useEffect(() => {
    if (rows && rows.length) {
      setRowsResults(rows.length);
    }
  }, [rows.length]);

  useEffect(() => {
    if (subRowKey && data.length) {
      const areThereSubRows = data.some(row => row[subRowKey].length);
      setAreThereSubRows(areThereSubRows);
    }
  }, [subRowKey, data]);

  const toggleRow = index => {
    setExpandedRows(prevExpandedRows => {
      if (prevExpandedRows.includes(index)) {
        return prevExpandedRows.filter(rowIndex => rowIndex !== index);
      } else {
        return [...prevExpandedRows, index];
      }
    });
  };

  const onSortByColumn = (id: string) => {
    setHeaders(prevState => {
      let sortMethod: SortMethod;
      let sortFilter: string;

      const updatedList = prevState.map(header => {
        if (header.accessor === id) {
          switch (header.sortByColumn.method) {
            case undefined:
              sortMethod = SortMethod.DESC;
              sortFilter = header.sortByColumn?.filterName;
              break;

            case SortMethod.DESC:
              sortMethod = SortMethod.ASC;
              sortFilter = header.sortByColumn?.filterName;
              break;

            case SortMethod.ASC:
              sortMethod = undefined;
              sortFilter = undefined;
              break;
          }

          return {
            ...header,
            sortByColumn: {
              ...header.sortByColumn,
              isActive: sortMethod ? true : false,
              method: sortMethod,
              isSortable: true,
            },
          };
        } else {
          return {
            ...header,
            sortByColumn: {
              ...header.sortByColumn,
              isActive: false,
              method: undefined,
            },
          };
        }
      });

      setSortFilter(sortFilter);
      setSortMethod(sortMethod);
      return updatedList;
    });
  };

  return (
    <div className="h-full overflow-x-auto" ref={containerRef}>
      {rowsResults && (
        <table
          className={`w-full border-separate border-spacing-0 table-auto sturdy min-w-max ${className}`}
          {...getTableProps()}
        >
          <thead>
            <tr>
              {headers.map((column, index) => (
                <th
                  key={`${column.Header}+${index}`}
                  className={`sticky bg-white z-10 group select-none font-aeonik px-6 text-left h-12.5 text-body-14 text-neutral-100 top-0 ${
                    selectRows && index === 0 ? 'w-16' : ''
                  } ${areThereSubRows ? 'pl-12' : 'pl-6'} ${
                    column?.sortByColumn?.isSortable && 'cursor-pointer'
                  } ${
                    variant === 'dark'
                      ? 'bg-mvs-bg-primary border-b border-mvs-border-lighter'
                      : 'border-t bg-white border-b border-neutral-40'
                  }
                  `}
                  {...column.getHeaderProps()}
                  onClick={() => {
                    if (!column?.sortByColumn?.isSortable) return;
                    onSortByColumn(column.id);
                  }}
                >
                  <div
                    className={`flex items-center ${
                      column?.headerType === HeaderCellType.NUMBER
                        ? column?.sortByColumn?.isSortable
                          ? '-mr-4 justify-end'
                          : 'justify-end'
                        : 'text-left'
                    }`}
                  >
                    {column.render('Header')}
                    {column?.sortByColumn?.isSortable && (
                      <ArrowUpward
                        className={`group-hover:visible ml-1 ${
                          column?.sortByColumn && column?.sortByColumn?.isActive
                            ? 'visible fill-primary-60'
                            : 'invisible fill-neutrals-200'
                        } ${
                          column?.sortByColumn?.method === SortMethod.ASC
                            ? 'close-menu'
                            : 'open-menu'
                        }`}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => {
              prepareRow(row);

              const subRows = row?.original?.[subRowKey];
              const fontBold = row?.original?.has_font_bold;
              return (
                <Fragment key={`${row[index]}.${index}`}>
                  <tr
                    key={`${row[index]}.${index}`}
                    className={`relative h-4 ${
                      !subRows?.length ? 'hover:bg-gray-50' : ''
                    } ${onClickColumn ? 'cursor-pointer' : 'cursor-default'}`}
                    {...row.getRowProps()}
                    {...row?.original?.metadata}
                    onClick={() => {
                      if (subRows?.length) {
                        toggleRow(index);
                      }
                    }}
                  >
                    {row.cells.map((cell, cellIndex) => {
                      const isHide = cell.column.Header === hideColumn;
                      const columnId = cell.column.id;
                      const rowData = row?.original?.data;
                      const hasError =
                        cell.column.Header === errorColumn &&
                        row?.values?.status?.props?.status ===
                          OrdersStatus.Error;

                      return (
                        <td
                          {...cell.getCellProps()}
                          key={`${cell.value}+${cellIndex}`}
                          className={`${
                            rowMode === 'compact' ? 'h-8' : 'h-10'
                          } border-solid text-body-14 leading-[1.375rem]  whitespace-pre-wrap ${
                            areThereSubRows ? 'pl-12' : 'pl-6'
                          } ${
                            fontBold
                              ? 'font-medium text-neutrals-100 bg-neutrals-10'
                              : 'text-neutral-80'
                          } ${
                            onClickColumn ? 'cursor-pointer' : 'cursor-default'
                          } ${
                            variant === 'dark'
                              ? 'border-b border-mvs-border-lighter'
                              : 'border-b border-neutral-40'
                          }`}
                          onClick={e => {
                            if (
                              onClickColumn &&
                              (e.target as HTMLInputElement | null)?.type !==
                                'checkbox'
                            ) {
                              onClickColumn(index);
                            }
                          }}
                        >
                          {subRows?.length ? (
                            <ArrowUp
                              className={`absolute top-4 left-4 fill-neutrals-200 ${
                                expandedRows.includes(index)
                                  ? 'open-menu'
                                  : 'close-menu'
                              }`}
                            />
                          ) : null}
                          <div
                            className={`flex text-body-14 ${
                              cell.column?.headerType === HeaderCellType.NUMBER
                                ? 'pr-6 justify-end'
                                : ''
                            } ${
                              onClickColumn || subRows?.length
                                ? 'cursor-pointer'
                                : 'cursor-default'
                            } ${subRows?.length ? 'hover:underline' : ''} ${
                              hasError && 'text-yellow-40'
                            }
                                    ${isHide ? 'invisible hover:visible' : ''} 
                                  `}
                          >
                            {!hasError ? (
                              rowData ? (
                                rowData[columnId]
                              ) : (
                                cell.render('Cell')
                              )
                            ) : (
                              <ErrorText
                                text={
                                  rowData
                                    ? rowData[columnId]
                                    : cell.render('Cell')
                                }
                              />
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>

                  {subRowKey && subRows?.length
                    ? subRows?.map((subRow, subRowIndex) => (
                        <tr
                          key={subRowIndex}
                          role="subrow"
                          className={
                            expandedRows.includes(index) ? '' : 'hidden'
                          }
                        >
                          {row.cells.map((cell, cellIndex) => (
                            <td
                              key={cellIndex}
                              className={`${
                                rowMode === 'compact' ? 'h-8' : 'h-10'
                              } bg-gray-50 text-body-14 text-neutral-80 pl-6 whitespace-pre-wrap ${
                                variant === 'dark'
                                  ? ''
                                  : 'border-b border-neutral-40 border-solid'
                              }`}
                            >
                              <div
                                className={`flex text-body-14 ${
                                  typeof subRow[cell.column.id] === 'number'
                                    ? 'pr-6 justify-end'
                                    : ''
                                }
                                  `}
                              >
                                {subRow[cell.column.id]}
                              </div>
                            </td>
                          ))}
                        </tr>
                      ))
                    : null}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      )}
      {rows.length === 0 &&
        (noResultsTemplate ? (
          noResultsTemplate
        ) : (
          <NoResults
            title={NOT_FOUND_MESSAGES.RECORDS_NOT_FOUND.title}
            description={NOT_FOUND_MESSAGES.RECORDS_NOT_FOUND.description}
          />
        ))}
    </div>
  );
};

export default Table;
