import { useEffect, useState } from 'react';

interface ToggleButtonProps {
  isButtonChecked: boolean;
  onCheckBoxChanged: (isChecked: boolean) => void;
}

const ToggleButton = ({
  isButtonChecked,
  onCheckBoxChanged,
}: ToggleButtonProps) => {
  const [isChecked, setIsChecked] = useState(isButtonChecked);

  useEffect(() => {
    setIsChecked(isButtonChecked);
  }, [isButtonChecked]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onCheckBoxChanged(!isChecked);
  };

  return (
    <>
      <label className="relative inline-flex cursor-pointer select-none items-center">
        <input
          type="checkbox"
          className="sr-only"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span
          className={`slider flex h-5 w-9 items-center rounded-full p-0.5 duration-200 ${
            isChecked
              ? 'bg-mvs-brand-primary border border-mvs-border-main inputToggleButtonChecked'
              : 'bg-mvs-bg-secondary border border-mvs-border-lighter inputToggleButtonUnchecked'
          }`}
        >
          <span
            className={`dot h-4 w-4 rounded-full bg-white duration-200 ${
              isChecked ? 'translate-x-3.5' : ''
            }`}
          ></span>
        </span>
      </label>
    </>
  );
};

export default ToggleButton;
