import { FunctionComponent, ReactNode } from 'react';

import { useAppSelector } from 'hooks';

import { Features } from 'utils/enums/features';

interface FeatureProps {
  name: Features;
  children: ReactNode;
}

const Feature: FunctionComponent<FeatureProps> = ({ name, children }) => {
  const feature = useAppSelector(state =>
    state.config.features.find(feature => feature.name === name)
  );

  if (feature && feature.active) {
    return <>{children}</>;
  }

  return null;
};

export default Feature;
