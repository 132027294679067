import { FunctionComponent } from 'react';

import Spinner from 'components/Spinner';

import { useAppSelector } from 'hooks';

interface LayoutProps {
  children?: React.ReactNode;
}

const AuthLayout: FunctionComponent<LayoutProps> = ({ children }) => {
  const sessionLoading = useAppSelector(state => state.session.sessionLoading);

  return (
    <div className="h-screen w-screen">
      {sessionLoading && (
        <div className="absolute bg-white flex w-full h-screen items-center justify-center z-50">
          <Spinner />
        </div>
      )}
      {children}
    </div>
  );
};

export default AuthLayout;
