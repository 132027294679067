import { Field, ErrorMessage } from 'formik';
import {
  FunctionComponent,
  FocusEvent,
  ChangeEvent,
  KeyboardEvent,
} from 'react';
import { NumericFormat } from 'react-number-format';

import styles from './styles.module.css';

interface NumericFormatProps {
  title?: string;
  name: string;
  disabled?: boolean;
  className?: string;
  onBlur?(event: FocusEvent<HTMLInputElement, Element>): void;
}

const handleOnKeyPress = (
  e: ChangeEvent<HTMLInputElement> & KeyboardEvent<HTMLInputElement>
) => {
  if (e.key === 'Delete') {
    e.preventDefault();
  }
};

const InputNumericFormat: FunctionComponent<NumericFormatProps> = ({
  title,
  name,
  disabled,
  className = '',
}) => {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <div className={`relative ${disabled ? 'opacity-60' : ''} `}>
          <NumericFormat
            {...field}
            className={`${meta.touched && meta.error ? styles.invalid : ''} ${
              styles.textBox
            } ${className} font-aeonik`}
            decimalScale={2}
            fixedDecimalScale={true}
            allowNegative={false}
            autoComplete="off"
            valueIsNumericString={true}
            disabled={disabled}
            onKeyDown={handleOnKeyPress}
            required
          />
          <span
            className={`${meta.touched && meta.error ? styles.invalid : ''} ${
              styles.bar
            }`}
          />
          <label className={`${styles.tag} font-aeonik`}>{title}</label>
          <ErrorMessage
            name={name}
            component="span"
            className={`${styles.invalid} block text-xs text-danger-40`}
          />
        </div>
      )}
    </Field>
  );
};

export default InputNumericFormat;
