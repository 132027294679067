import { createSlice } from '@reduxjs/toolkit';
import { isEmpty, isNil } from 'ramda';

import { getCookie } from 'hooks/useCookies';

const initialState = {
  sessionLoading: false,
  authorization: '',
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessionLoading(state, action) {
      state.sessionLoading = action.payload;
    },
    setAuthorization(state, action) {
      state.authorization = action.payload;
    },
    checkAuth(state) {
      const authorization = getCookie('authorization');
      if (!isNil(authorization) && !isEmpty(authorization)) {
        state.authorization = authorization;
      }
    },
  },
});

export const sessionActions = sessionSlice.actions;
