import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { BRANDS_LIST_QUERY } from 'api/product/attributes';

import { parseJSON } from 'utils/functions/parseJSON';

import { DropdownItem } from 'types/dropdownItem';

const useLobList = () => {
  const [lobListItems, setLobListItems] = useState<DropdownItem[]>([]);
  const [lobContext, setLobContext] = useState<string[]>([]);
  const [firstOrDefaultLob, setFirstOrDefaultLob] = useState('');

  const {
    data: brandsQueryResponse,
    loading: loadingBrands,
    error: brandsError,
  } = useQuery<{ brands: DropdownItem[] }>(BRANDS_LIST_QUERY);

  const getSelectedLob = () => {
    if (!lobListItems.length) {
      return '';
    }
    return lobListItems.find(lob => lob.isSelected)?.value || '';
  };

  const findLobByValue = (value: string) => {
    return lobListItems.find(lob => lob.value === value);
  };

  const getSelectedLobValues = (dropdownItems: DropdownItem[]) => {
    return dropdownItems
      .filter(item => item.isSelected)
      .map(item => item.value);
  };

  const setLobList = (lobListFromApi: DropdownItem[]) => {
    if (lobListFromApi.length) {
      const [currentLob] = lobListFromApi.map(item => item.value);
      setFirstOrDefaultLob(currentLob);
    }

    setLobListItems(lobListFromApi);
    localStorage.setItem('cachedLobList', JSON.stringify(lobListFromApi));
  };

  useEffect(() => {
    if (brandsQueryResponse) {
      const { brands } = brandsQueryResponse;

      if (brands) {
        const lobListFromApi = brands.map((brand: DropdownItem) => {
          const { title, value } = brand;
          return {
            title,
            value,
            isSelected: false,
          };
        });

        const cachedLobList = parseJSON(localStorage.getItem('cachedLobList'));

        if (!cachedLobList) {
          localStorage.setItem('cachedLobList', JSON.stringify(lobListFromApi));
          setLobList(lobListFromApi);
        } else {
          const updatedLobList = lobListFromApi.map((lob: DropdownItem) => {
            const cachedLob = cachedLobList.find(
              (cachedLob: DropdownItem) => cachedLob.value === lob.value
            );
            if (!cachedLob) {
              return lob;
            } else {
              return { ...lob, isSelected: cachedLob.isSelected };
            }
          });

          setLobList(updatedLobList);
        }
      }
    }
  }, [brandsQueryResponse]);

  useEffect(() => {
    if (lobListItems) {
      const lobContextList: string[] = lobListItems.reduce(
        (accumulator: string[], lob: DropdownItem) => {
          const { value } = lob;
          return lob.isSelected ? [...accumulator, value] : accumulator;
        },
        []
      );

      setLobContext(lobContextList);
    }
  }, [lobListItems]);

  return {
    lobListItems,
    lobContext,
    isLobLoading: loadingBrands,
    lobError: brandsError,
    setLobListItems: setLobList,
    getSelectedLob,
    getSelectedLobValues,
    findLobByValue,
    firstOrDefaultLob,
  };
};

export default useLobList;
