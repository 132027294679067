import { IParty } from 'types/party';

export interface OrderStatusItem {
  createdAt: string;
  description: string;
  id: string;
  name: OrdersStatus;
  orderId: string;
  processedBy: IParty;
  updatedAt: string;
}

export enum OrdersStatus {
  Allocated = 'ALLOCATED',
  Canceled = 'CANCELED',
  Draft = 'DRAFT',
  Error = 'ERROR',
  Fulfilled = 'FULFILLED',
  PartiallyShipped = 'PARTIALLY_SHIPPED',
  InProcess = 'IN_PROCESS',
  OnHold = 'ON_HOLD',
  Ordered = 'ORDERED',
  Shipped = 'SHIPPED',
  UnShipped = 'UNSHIPPED',
  Delivered = 'DELIVERED',
  InTransit = 'IN_TRANSIT',
}
