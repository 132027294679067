export const Check = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 17 18"
      fill="#00f"
      version="1.1"
      id="svg44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs48" />
      <path
        d="M 14.99,6 13.58,4.58 6.99,11.17 4.41,8.6 2.99,10.01 6.99,14 Z"
        fill="#0000ff"
        id="path42"
      />
    </svg>
  );
};
