import { FunctionComponent, useEffect, useState } from 'react';

import ClearIcon from 'assets/icons/clear.svg';
import SearchIcon from 'assets/icons/search.svg';

export interface SearchProps {
  className?: string;
  customWidth?: string;
  initialSearchValue?: string;
  placeholder?: string;
  maxLength?: number;
  onChange?: (event: string) => void;
}

const Search: FunctionComponent<SearchProps> = ({
  className,
  customWidth,
  placeholder,
  onChange,
  initialSearchValue,
  maxLength = 50
}: SearchProps) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (!initialSearchValue) {
      setIsEmpty(true);
      setSearchValue('');
      onChange && onChange('');
    }
  }, [initialSearchValue]);

  const handleOnChange = event => {
    if (event?.target?.value) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
    setSearchValue(event?.target?.value || '');
    onChange && onChange(event?.target?.value || '');
  };

  return (
    <div
      className={`flex items-center ${
        customWidth ? customWidth : 'w-77.5'
      } h-8.5 p-1.7 px-4 border border-gray-inactive focus-within:border focus-within:border-primary-60 rounded-.5 ${className}`}
    >
      <SearchIcon className="fill-inactive mr-2.5" />
      <input
        className="text-m font-aeonik outline-none w-full"
        placeholder={`${placeholder ? placeholder : 'Search'}`}
        onChange={handleOnChange}
        maxLength={maxLength}
        value={searchValue}
      />
      <ClearIcon
        className={`fill-inactive ml-2.5 ${isEmpty ? 'hidden' : ''}`}
        onClick={() => handleOnChange('')}
      />
    </div>
  );
};

export default Search;
