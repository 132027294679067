import { useEffect } from 'react';

export const useOnClickOutside = (ref, handleClick) => {
  useEffect(() => {
    const outsideListener = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClick(event);
      }
    };

    document.addEventListener('mousedown', outsideListener);
    document.addEventListener('touchstart', outsideListener);

    return () => {
      document.removeEventListener('mousedown', outsideListener);
      document.removeEventListener('touchstart', outsideListener);
    };
  }, [ref, handleClick]);
};

export default useOnClickOutside;
